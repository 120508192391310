@import url("./lib/reset.scss");
@import url('./lib/fonts.scss');
@import url("./lib/vars.scss");
@import url('@mantine/core/styles.css');

#root {
  min-height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  color: var(--color-black);
  font-size: var(--font-size);
  font-family: var(--font-family), Arial, sans-serif;
  background-color: var(--color-background);
  line-height: 1;

  &.reverted {
    filter: invert(100);
  }

  &::-webkit-scrollbar {
    display: none; 
    scrollbar-width: none;
  }
}

.page {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black-13);
  min-width: 360px;

  @media (width > 976px) {
    min-height: 100vh;
  }

  .content-wrapper {
    position: relative;
    display: flex;

    @media (width > 976px) {
      height: 100vh;
    }

    .content { 
      position: relative;
      width: 100%;
      padding: 20px 40px;
      padding-top: 0;
      padding-right: 20px;
      background-color: var(--color-background);
      margin: 10px 0;
      margin-left: var(--aside-width);
      border-radius: 16px 0 0 16px;
      overflow-y: auto;
      min-height: calc(100vh - 20px);

      @media (width <= 976px) {
        margin: 0;
        padding: 0;
        border-radius: 0;
      }
    }
  }

  .content-diary {
    padding: 20px 40px;
    width: 100%;
    background-color: var(--color-background);
    overflow: auto;
    min-height: 100vh;
    box-sizing: border-box;
  }

  .section-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--divider-color);

    @media (width <= 976px) {
      border: none;
      padding-bottom: 0;
    }

    h1 {
      font: 38px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);

      @media (width <= 976px) {
        font-size: 30px;
      }
    }
  }

  .section-content {
    margin-top: 20px;
  }
}


html[data-theme='light'] {
  .green-shadow, 
  .orange-shadow {
    visibility: hidden;
    opacity: 0;
  }

  .page { 
    @media (width <= 976px) {
      background-color: #F7F6F7;
    }
  }
}

html[data-theme='dark'] {
  .page { 
    @media (width <= 976px) {
      background-color: #0E0E0E;
    }
  }

  .page .content.content {
    background-image: 
      radial-gradient(circle at right calc(75% - 100px) , #00AB6D45, transparent 30%),
      radial-gradient(circle at calc(40% - 200px) bottom, rgb(255 135 8 / 20%) 0%, #0E0E0E 40%);
  }

  .modal-custom-overflow {
    .mantine-Modal-content {
      background: var( --color-black-15);

      .mantine-Modal-header {
        background: var( --color-black-15);
      }
    }

    .m-220c80f2:active {
      --_cb-bg: none;
    } 

    .m-220c80f2:hover {
      background-color: transparent;
      color: var(--white);
    }
  }

  .header-title-logout {
    h2 {
      max-width: 350px;
    }
  }
}

::-webkit-scrollbar {
  width: 3px; 
  height: 3px;
  background: var(--input-border-color);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-main);
  border-radius: 8px;
}

@-moz-document url-prefix() {
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--color-main) var(--input-border-color);
  }
}

@media (width <= 576px) {
  .react-datepicker-popper {
    .react-datepicker {
      width: min-content;
    }
  }
}

// mantine loader global styles
.loader-mini {
  .mantine-LoadingOverlay-loader {
    width: 15px !important;
    height: 15px !important;
  
    &::after {
      width: 15px !important;
      height: 15px !important;
      border-width: 2px !important; 
    }
  } 
}

.modal-custom-overflow {
  .mantine-Modal-overlay {
    --overlay-z-index: 3001 !important;
  }

  .mantine-Modal-inner {
    z-index: 4001 !important;
  }

  .mantine-Modal-content {
    overflow: unset;
  }

  .mantine-Modal-header {
    background-color: transparent !important;

    button {
      margin: 0 !important;

      &:hover {
        background-color: transparent !important;
        color: var(--color-black);
      }
    }

  
  }

  &.white-filter {
    .mantine-Modal-content {
      background: linear-gradient(313deg, rgb(255 255 255 / 0%) 0%, var( --modal-bg-filter-color) 100%) !important;
      backdrop-filter: blur(16px) !important;
    }
  }  
  
  &.blue-filter {
    .mantine-Modal-content {
      background: radial-gradient(159.15% 106.17% at 50.15% 95.89%, rgb(7 46 57) 0%, rgb(19 19 19) 65.67187905311584%) !important;

      .m-5df29311 { 
        padding: 0;
      }
    }

    .mantine-Modal-header {
      background-color: transparent !important;

      button {
        margin: 0 !important;
      }

      &:hover {
        button {
          background-color: transparent !important;
        }
      }
    }
  }

  &.modal-border {
    .mantine-Modal-content {
      border: 1px solid var(--input-border-color) !important;
    }
  }

  &.hidden-title {
    .mantine-Modal-header {
      position: absolute;
      right: 10px; 
    }
  }
}

.m_539e827b {
  position: relative;
  font: 15px 'Inter-Medium', Arial, sans-serif;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
  }
}

.m_539e827b:where([data-active]) {
  border-color: transparent;

  span {
    color: var(--color-main);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-main);
    transition: width 0.2s ease-in-out; 
    border-radius: 8px;
  }

}