.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 155px);

  @media (width <= 976px) {
    height: 100vh;
  }

  .error-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @media (width <= 500px) {
      gap: 20px;
    }

    .wrapper-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      h1 {
        font: 28px "Inter-Medium";
        color: var(--color-black);

        @media (width <= 500px) {
          font-size: 20px;
        }
      }     
    }

    svg {
      width: 150px;

      @media (width <= 500px) {
        width: 120px;
      }
        
      g g g path{
        stroke: var(--color-black);
      }
    }
  }


}
