.bonus-slide {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;

  @media (width <= 1400px) {
    flex-direction: column-reverse;
  }

  .bonus-slide-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
        
    .info-mini-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      margin-bottom: 20px;

      .info-mini-box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
      }

      @media (width <= 1400px) {
        & {
          flex-wrap: wrap;
        }
      }
    }

    .info-mini-wrapper-adaptive {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 10px;
      gap: 10px;
      width: 100%;
      margin-bottom: 10px;
      
      .info-mini-wrapper-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        gap: 10px;
      }
    }
  }

  .bonus-slide-aside {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 300px;
    z-index: 10;


    @media (width <= 1400px) {
      max-width: 100%;
    }
  }
}



