.track {
  &::before {
    background-color: #D9D9D9;
  }
}
  
.mark {
  display: none;
  width: rem(6px);
  height: rem(6px);
  border-radius: rem(6px);
  transform: translateX(rem(-3px)) translateY(rem(-2px));
  color: var(--color-orange) !important; 
  border-color:  var(--color-orange) !important;

  &[data-filled] {
    // border-color: white !important;
    display: none;
  }
}
  
.markLabel {
  font-size: var(--mantine-font-size-xs);
  margin-bottom: rem(5px);
  margin-top: 0;
}
  
.thumb {
  width: rem(16px);
  height: rem(16px);
  background-color: var(--mantine-color-white);
  border-width: rem(1px);
  box-shadow: var(--mantine-shadow-sm);
  border-color:  var(--color-orange) !important;
}

.bar {
  background: var(--color-orange) !important;
}

.label {
  background:  var(--color-orange) !important;

}