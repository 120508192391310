.slider {
  .verification-slide {
    padding: 30px;
    border-radius: 8px;
    background-color: var(--table-bg-color);

    @media (width <=976px) {
      background-color: unset;
      padding: 0;
    }

    .faq-questions {
      margin-top: 20px;

      a {
        display: block;
        font: 12px 'Inter-Regular';
        color: #00AB6D;
        text-decoration: underline;
        margin-top: 10px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

html[data-theme='dark']  {
  .verification-slide {
    .faq-questions a {
      color: var(--white);
    }
  }
}