aside {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: var(--aside-width);
  max-width: var(--aside-width);
  height: 100%;
  padding: 30px 20px;
  background-color: var(--color-black-13);
  box-sizing: border-box;
  overflow: hidden auto;
  
  @media (width <= 976px) {
    display: none;
  }

  &::-webkit-scrollbar {
    display:none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  .logotype { 
    min-width: 213px;
    margin-bottom: 20px;
  }

  .accounts {
    margin-bottom: 10px;

    .account-wrapper {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      gap: 10px;     
    }
  }

  .actions {
    margin: 20px 0;
    
    .pnl {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;

      p.title {
        font: 12px 'Inter-Light', Arial, sans-serif;
        color: var(--white);
        margin-bottom: 5px;
      }

      p.value {
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--color-main);
      }

      .value_plus {
        color: var(--color-main) !important;
      }

      .value_minus {
        color: var(--color-red) !important;
      }

    }

    .btn {
      margin-top: 5px;
    }
  }

  .socials {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: #BDBDBD;
      text-align: center;
      max-width: 70%;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin: 11px 0;

      .social-telegram {
        &:hover {
          svg path {
            fill: #08C;
          }
        }
      }
      
      .social-discord {
        &:hover {
          svg path {
            fill: #5865F2;
          }
        }
      }

      li {
        a {
          svg {
            max-width: 100%;

            path {
              fill: #BDBDBD;
            }
          }
        }
      }
    }
  }
}
