.gaModalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  
  .gaQrInfo {
    font: 15px "Inter-Regular";
    color: var(--color-black);
    line-height: 1.5;
      
    strong {
      font-weight: 600;
    }
  }
  
  .gaKeyWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  
    .gaKey {
      font: 15px "Inter-Medium";
      color: var(--color-black);
    }
  
    button {
      display: flex;
  
      &:hover,
      &:focus {
        svg path {
          stroke: var(--color-main);
        }
      }
    }
  }
  
  .gaModalActions {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }
}