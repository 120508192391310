.wallet {
  display: flex;
  flex-direction: column;
  gap: 10px;  
  background-color: var(--card-bg-color);
  border-radius: 16px;
  padding: 20px;

  .walletName {
    display: flex;
    align-items: center;
    gap: 5px;
    font: 15px "Inter-Regular", Arial, sans-serif;
    margin-bottom: 10px;
  }

  .box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    align-items: flex-start;

    .walletAddressContainer {
      align-items: center;
    }

    div {
      display: flex;
      flex-direction: row;
      gap: 5px;
      font: 12px "Inter-Regular", Arial, sans-serif;

      p {
        text-wrap: pretty;
        max-width: 120px;
        font: inherit;
      }

      span {
        font: 12px "Inter-Regular", Arial, sans-serif;
        color: #737573;
      }
    }
  }

  .actionDisabled {
    opacity: 0.5;

    button {
      cursor: not-allowed;
    }
  }
}
