.skeleton {
  div {
    border: none !important;
  }
}

html[data-theme='dark'] {
  .skeleton {
    div {
      border: none !important;
  
      &::before {
        background-color:#131313 !important;
      }
        
      &::after {
        background-color: #3A3A3A !important;
      }
    }
  }
}