canvas {
  position: absolute;
  overflow: hidden hidden;
  width: 100%;
  margin: 0;
}

.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../shared/assets/images/screen.jpeg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 

  .bodyContainer {
    position: relative;
    width: 610px;
    height: max-content;
    border-radius: 16px;
    background: linear-gradient(224deg, rgb(2 2 2 / 95%) 0%, rgb(41 41 41 / 95%) 100%);
    padding: 30px;
    margin-top: 150px;

    .animationWrapper {
      position: absolute;
      top: -300px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 5;
      mix-blend-mode: lighten;

      img {
        width: 300px;
        height: 300px;

        @media (width <= 1400px) {
          width: 250px;
          height: 250px;
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      height: 100%;
      justify-content: flex-start;
      z-index: 10;

      h1 {
        font: 48px 'Inter-Medium', Arial, sans-serif;
        color: var(--white);
        margin-bottom: 7px;
      }
    }
  }
}

