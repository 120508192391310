.accountSelect {
  position: relative;
  color: var(--color-white);

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .selectListWrapper {
    padding: 5px;
    position: absolute;
    top: 33px;
    left: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-black-1e);
    z-index: 100;
  }

  .selectBlock {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: var(--white);


    &:hover {
      color: var(--color-main);

      & svg path {
        stroke: var(--color-main);
      }
    }
  }

  .selectList {
    height: 100%;
    max-height: 126px;
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      padding: 6px 15px;
      font: 13px "Inter-Regular", Arial, sans-serif;
      cursor: pointer;
      color: var(--color-white);
      border-radius: 8px;

      span {
        color: var(--white);
      }

      &:hover {
        background-color: var(--color-orange);
      }

      img {
        height: 15px;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}

.active {
  svg {
    transform: rotate(180deg);
  }
}