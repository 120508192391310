.referralsPage {
  height: calc(100% - 190px);

  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
    height: calc(100% - 95px);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    @media (width <= 1405px) {
      height: auto;
    }

    .container {
      display: flex;
      gap: 10px;
      height: 100%; 
      max-height: 100%;
      overflow: auto; 

      @media (width <= 1405px) {
        flex-direction: column-reverse;
      }

      .table {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }
    }
  }
}
  