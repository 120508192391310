.rebateHistoryTableAdaptive {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .historyTableWrapper {
    display: flex;
    flex-direction: rows;
    align-items: center;
    justify-content: center;

    [class*="empty"] {
      min-height: 175px;
      justify-content: flex-end;
    }
  }
  
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    h3 {
      font: 18px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);
      max-width: 185px;
    }
  
    .filterBtn {
      cursor: pointer;
      transition: transform 0.2s ease;
  
      &:active {
        transform: scale(0.9);
      }
  
      &:hover {
        path {
          stroke: var(--color-orange);
        }
      }
  
      path {
        stroke: var(--color-black);
      }
    }
  }
  
  .table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 690px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 20px;
      
    .tableState {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 175px;
      max-height: 410px;
    }
  
    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }
  }

  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 175px;
  }
}
  
.drawer {
  [class*="MuiPaper-root"] {
    padding: 35px 25px 10px !important;
  }
  
  .filterWrapper {
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 20px;
    max-height: 70vh;
    
    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }
  
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
  
      .closeBtn {
        &::before {
          background: var(--color-black);
        } 
          
        &::after {
          background: var(--color-black);
        }
      }
  
      h3 {
        font: 18px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
      }
    }
  
    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .sortItemsContainer {
        max-height: 116px;
      }
  
      .typesItemsContainer {
        max-height: 185px;
      }
  
      .selectItem {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-black);

        img {
          width: 20px;
        }
      }
  
      [class*="data-picker"] {
        max-width: 100%;
      }
    }
  }
  
  [class*="root-MuiModal-backdrop"] {
    backdrop-filter: blur(3px);
  }
  
  
  [class*="MuiPaper-root"] {
    background-color: var(--input-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px 25px;
    margin: 0 15px;
  }
}