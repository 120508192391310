.assetsTable {
  .content {
    width: 100%;
    height: 100%;
    padding: 5px;
    
    .header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #E9E9E9;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding-right: 20px;
      height: 56px;
  
      .after {
        position: absolute;
        bottom: -15px;
        left: 0;
        background: var(--table-bg-color);
        width: 100%;
        height: 16px;
      }
    
      .filters {
        display: flex;
        align-items: center;
        margin-left: -1px;
        
        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          padding: 15px 30px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          background-color: transparent;
          color: #828282;
          transition: margin 0.3s ease;
          border: 1px solid transparent;
          z-index: 5;
          font: 13px "Inter-Regular", Arial, sans-serif;
          text-align: start;
          height: 55px;

          p {
            font: inherit;
            color: inherit;
            max-width: 110px;
          }
  
          @media (width <= 500px) {
            padding: 10px 20px;
          }
            
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            border-radius: 5px;
            font: 12px "Inter-regular";
            color: var(--white);
          }
        }
  
        button:nth-child(2) {
          margin-left: -15px;
  
          span {
            background-color: var(--color-main);
          }
        }
  
        button:nth-child(3) {
          margin-left: -15px;
  
          span {
            background-color: var(--color-orange);
          }
        }
  
        button:hover {
          position: relative;
          background-color: var(--color-white);
          border: 1px solid #E9E9E9;
          z-index: 10;
  
          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%; 
            height: 3px; 
            background-color: #FFF; 
          }
        }
  
        button:focus {
          position: relative;
          background-color: var(--color-white);
          border: 1px solid #E9E9E9;
          z-index: 10;
  
          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%; 
            height: 3px; 
            background-color: #FFF; 
          }
        }
  
        button.activeFilter {
          position: relative;
          background-color: var(--color-white);
          border: 1px solid #E9E9E9;
          box-shadow: 2px 0 5px -1px rgb(173 173 173 / 55%);
          z-index: 10;
          margin-left: 0;
          color: var(--gray-dark);
  
          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%; 
            height: 3px;
            background-color: #FFF; 
          }
        }
      }
  
      .search {
        border: 1px solid var(--input-border-color);
        background-color: var(--input-bg-color);
        border-radius: 8px;
  
        input {
          background: none;
          border: none;
          font: 11px "Inter-Regular";
          color: var(--color-black);
        }
  
        svg {
          margin-right: 10px;
           
        }
  
        svg path {
          stroke: var(--input-border-color);
        }
      }
    }
    
    .body {
      border-radius: 0 0 16px 16px;
      background: var(--table-bg-color);
      width: 100%;
      height: 100%;
      max-height: 345px;
      min-height: 345px;
      overflow: auto;
  
      .emptyTable {
        height: calc(100% - 100px);

        [class*="empty"] {
          height: 100%;
        }
      }
    }
  }
}
  
.table {
  width: 100%;
  border-spacing: 0 15px;
  border-collapse: separate;
  padding: 20px 30px 10px;  padding: 0 10px;  

  [class*="empty"] {
    margin: 0;
  }
  
  thead {
    position: sticky;
    top: 15px;
    z-index: 99;
    text-align: left;
    border-spacing: 0 15px;
    border-collapse: separate;
  }
  
  tr {
    height: 40px;
    text-align: left;
    font: 11px "Inter-Regular";
  
  
    th:first-child {
      border-top-left-radius: 8px; 
      border-bottom-left-radius: 8px;
      padding-left: 20px;
    }
  
    th:last-child {
      border-top-right-radius: 8px; 
      border-bottom-right-radius: 8px;
    }
  
    td:first-child {
      padding-left: 20px;
    }
  }
    
  th {
    height: 26px;
    cursor: pointer;
    font: 11px "Inter-Regular";
    color: var(--table-column-text-color);
    text-align: left;
    background: var(--table-column-color);
    vertical-align: middle;

    .info {
      g path {
        stroke: #BCBCBC !important;
      }
    }
  
    &:hover {
      color: var(--table-column-text-color-hover);
  
      svg g path{
        stroke: var(--table-column-text-color-hover);;
      }
  
      .info {
        g path {
          stroke: #BCBCBC;
        }
      }
    }
    
    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
    
  td {
    border-bottom: 1px solid var(--table-border-color);
    font: 13px "Inter-Regular";
    color: #212121;
    box-sizing: border-box;
  
    & > div {
      margin-right: 10px;
    }
  }
    
  .thWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 5px;
    white-space: nowrap;
  
    &.activeColumn {
      color: var(--color-main);
  
      svg path {
        stroke: var(--color-main);
      }
    }
  }
  
  .asset {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-right: 40px;
  
  
    span {
      white-space: nowrap;
    }
  
    img {
      width: 22px;
    }
  
    .symbol {
      font: 13px "Inter-Regular";
      color: var(--color-black);
    }
  
    .name {
      font: 11px "Inter-Regular";
      color: #737573;
    }
  }
  
  .quantity {
    .balance {
      font: 13px "Inter-Regular";
      color: var(--color-black);
      margin-right: 5px;
    }
  
    .available{
      white-space: nowrap;
      font: 11px "Inter-Regular";
      color: #737573;
    }
  }
  
  .pnl {
    font: 13px "Inter-Regular";
  
    .zero {
      color: var(--color-black);
    }
  
    .minus {
      color: var(--color-red);
    }
  
    .plus {
      color: var(--color-main);
    }
  }
      
  .output {
    .assetAction {
      display: flex;
      align-items: center;
      gap: 5px;
      font: 13px "Inter-Regular";
      color: var(--color-black);
  
      &:hover {
        color: var(--color-orange);
      }
    }
  } 
}
  
.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);
}
  
html[data-theme='dark'] {
  .assetsTable {
    .header {
      border: none;
      background-color: #343434;
      height: unset;
  
      .filters {
        margin-left: 0;
          
        button {
          border: none;
          z-index: 10;
        }
  
        button:nth-child(2) {
          box-shadow: 2px 0 4px -2px rgb(0 0 0 / 45%);
        }  
  
        button:nth-child(3) {
          box-shadow: 2px 0 4px -2px rgb(0 0 0 / 45%);
        }
  
        button:hover {
          position: unset;
          background-color: var(--color-black-13);
          border: none;
        
  
          &::before {
            height: 0;
          }
        }
  
        .activeFilter {
          border: none;
          box-shadow: 2px 0 4px -1px rgb(0 0 0 / 55%);
          background-color: var(--color-black-1e);
          z-index: 11;
  
          &::before {
            height: 0;
          }
        }
      }
    }
  
    table {
      background: var(--color-black-1e);
  
    }
  }
}