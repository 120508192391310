.input-text {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    svg path {
      stroke: var(--color-main) !important;
    }
  }  
  
  input {
    outline: none;
    box-sizing: border-box;
    width: 100%;
    font: 13px 'Inter-Regular', Arial, sans-serif;
    color: var(--color-black);
  }

  input[type='text'] {
    background-color: var(--input-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    padding: 12px;
  }     
  
  input[type='number'] {
    background-color: var(--input-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    padding: 12px;
  }   
  
  input[type='password'] {
    background-color: var(--input-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    padding: 12px;

    @media (width <=500px) {
      font-size: 10px;
    }
  }  


  &.input-error {
    input[type='text'] {
      border-color: var(--color-red) !important;
    }
  }

  label {
    font: 14px "Inter-Medium", Arial, sans-serif;
    color: var(--table-column-text-color);
  }


  .icon {
    position: absolute;
    right: 10px;
  }
}
