.authFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  padding-top: 25px;
  margin-top: auto;
  border-top: 1px solid #E9E9E9;

  [class*="bodyFooter"] {
    display: none;
    flex-direction: column;
    align-items: center;
    max-width: 210px;
    text-align: center;
    justify-content: center;

    p {
      margin-bottom: 10px;
    }
  }

  @media (width <= 976px) {
    flex-direction: column;
    gap: 10px;
    border-color: var(--divider-color);
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;

    [class*="bodyFooter"] {
      display: flex;
    }
  }

  .info {
    width: 50%;
    font: 13px "Inter-Regular";
    color: var(--color-font-gray);

    @media (width <= 976px) {
      border-top: 1px solid var(--divider-color);
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }
  }

  .social {
    @media (width <= 976px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    & ul {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    & svg path {
      fill: #BDBDBD;
    }

    .telegram {
      cursor: pointer;
      
      &:hover {
        svg path {
          fill: #08C;
        }
      }
    }
    
    .discord {
      &:hover {
        svg path {
          fill: #5865F2;
        }
      }
    }
  }
}