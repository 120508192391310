$semi-transparent-white: rgb(255 255 255 / 50%);

.transactionHistoryDetail {
  padding: 30px 30px 20px;
  background: radial-gradient(159.15% 106.17% at 50.15% 95.89%, rgb(7 46 57) 0%, rgb(19 19 19) 65.6718790531%);

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  
    .transferImage {
      width: 50px;
    }
  
    .typeTitle {
      font: 12px 'Inter-Regular', Arial, sans-serif;
      color: $semi-transparent-white;
      margin-bottom: 5px;
    }
  
    .transferValue {
      font: 32px 'Inter-Medium', Arial, sans-serif;
      color: var(--white);
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .sectionTitle {
      font: 12px 'Inter-Regular', Arial, sans-serif;
      color: $semi-transparent-white;
    }
  
    .sectioDetail {
      display: flex;
      align-items: center;
      gap: 10px;
      font: 12px 'Inter-Regular', Arial, sans-serif;
      color: var(--white);
  
      .subAccount {
        display: flex;
        align-items: center;
        gap: 5px;
    
        span {
          white-space: nowrap;
          min-width: 55px;
        }

        img {height: 20px;}
      }
  
      svg path { stroke: gray; }
    }
  }
  
  .statusWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    font: 12px "Inter-Regular", Arial, sans-serif;
    padding: 3px 10px;
    background: rgb(0 195 124 / 20%);
    border-radius: 4px;
    width: max-content;
    color: var(--color-main);
    margin: 25px auto 0;
  
    .transferStatus, .transferTime {
      color: inherit;
    }
  
    &.error {
      background: rgb(225 52 62 / 20%);
      color: var(--color-red);
    }
  }
  
  .errorMessage {
    font: 12px "Inter-Light", Arial, sans-serif;
    color: var(--color-red);
    text-align: center;
    margin: 20px 0;
  }
  
  [class*="btn"] {
    margin: 0 auto;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 515px !important;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  
  .shareBtn {
    background: rgb(255 255 255 / 5%);
    border-radius: 8px;
    padding: 13px;
    transition: all 0.2s ease;
    
    
    svg {display: flex;}

    &:hover {
      background-color: rgb(255 255 255 / 9%);;
    } 
    
    &:active {
      transform: scale(0.95);
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  svg {
    display: flex;
    width: 120px;
  }
}