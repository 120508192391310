.aside {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--input-border-color);
  background: var(--aside-bg-color);
  max-width: 75px;
  height: calc(100% - 40px);
  border-radius: 16px;
  z-index: 1000;
  margin: 20px;
  margin-right: 0;
  overflow: hidden;
  
  .logo {
    height: max-content;
    border-bottom: 1px solid var(--input-border-color);
    background: var(--aside-bg-color);
    border-radius: 16px;;
    padding: 20px;
    box-sizing: content-box;
    transition: transfrom 0.3s ease;
    cursor: pointer;


    &:hover svg {
      opacity: 1;
    }

    svg {
      display: flex;
      width: 35px;
      height: 35px;
      opacity: 0.8;
      box-sizing: content-box;
      transition: transfrom 0.3s ease;
    }
  }

  .navbar {
    display: flex;
    width: 100%;
    margin: auto 0;

    .navbarMenu {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .navbarLink {
        position: relative;
        display: inline-flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;

        &:active svg{
          transform: scale(0.9);
        }

        a {
          display: flex;
          width: 100%;
          padding: 30px 0;
        }

        svg {
          display: flex;
          width: 100%;
        }

        &:hover {
          svg path{
            stroke: var(--color-orange);
          }
        }
      
        &.active {
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -1px;
            bottom: 0;
            width: 3px;
            height: 25px;
            background-color: var(--color-orange);
            box-shadow: -2px 0 18px 1px var(--color-orange);
            border-radius: 8px;
          }

    
          svg path{
            stroke: var(--color-orange);
          }
          
        }
      }
    }
  }

  .socialLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 70%;
    padding-top: 40px;
    margin: 0 5px;
    margin-bottom: 50px;
    border-top: 1px solid var(--input-border-color);
    box-sizing: content-box;

    .socialTelegram {
      &:hover {
        path {
          fill: #08C;
        }
      }

      &:active {
        transform: scale(0.9);
      }
    }
    
    .socialDiscord {
      &:hover {
        path {
          fill: #5865F2;
        }
      }

      &:active {
        transform: scale(0.9);
      }
    }

    svg {
      cursor: pointer;
      display: flex;


      path {
        fill: #BDBDBD;
      }
    }
  }
}

html[data-theme='light'] {
  .navbarLink {
    svg path {
      stroke: black;
    }
  }
}