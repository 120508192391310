.subContainer {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    min-height: 42px;

    h1 {
      font: 26px "Inter-SemiBold";
      color: var(--color-black);
    }
  }

  .subaccountsWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;

    .wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(100px, auto);
      gap: 10px;
      width: 100%;
      padding: 5px 0;

      .addSubAccount {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--input-border-color);
        border-radius: 16px;
        min-height: 207px;
        min-width: 260px;

        &:hover {
          span {
            color: var(--color-black);
          }

          svg path {
            stroke: var(--color-black);;
          }
        }

        &:active {
          span {
            color: var(--color-main);
          }

          svg path {
            stroke: var(--color-main);
          }
        }

        span {
          font: 16px "Inter-Medium";
          color: #767676;
        }
      }

      .subaccountWrapper {
        width: 100%;
      }
    }

    a {
      color: var(--color-black);
    }
  }
}
