.infoMiniContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 59px;
  max-height: 59px;
  background: radial-gradient(circle at 100% 100%, #FFF 0, #FFF 6px, transparent 6px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #FFF 0, #FFF 6px, transparent 6px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #FFF 0, #FFF 6px, transparent 6px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #FFF 0, #FFF 6px, transparent 6px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#FFF, #FFF) 50% 50%/calc(100% - 4px) calc(100% - 16px) no-repeat,
    linear-gradient(#FFF, #FFF) 50% 50%/calc(100% - 16px) calc(100% - 4px) no-repeat,
    linear-gradient(90deg, rgb(0 171 109 / 66%) 0%, transparent 74%) no-repeat;
  border-radius: 8px;
  padding: 25px;
  box-sizing: content-box;

  @media (width <=976px) {
    width: auto;
    padding: 15px;
    padding-left: 20px;
  }

  p {
    color: var(--color-black);
    font: 13px "Inter-Regular", Arial, sans-serif;
    margin-bottom: 10px;
  }

  span {
    color: var(--color-black);
    font: 26px "Inter-Regular", Arial, sans-serif;

    @media (width <=976px) {
      font-size: 16px;
    }
  }

  @media (width <= 976px) {
    [class*="loader"] {
      height: 20px;
    }
  }
}

html[data-theme='dark'] {
  .infoMiniContainer {
    background: radial-gradient(circle at 100% 100%, #1E1E1E 0, #1E1E1E 6px, transparent 6px) 0% 0%/8px 8px no-repeat,
      radial-gradient(circle at 0 100%, #1E1E1E 0, #1E1E1E 6px, transparent 6px) 100% 0%/8px 8px no-repeat,
      radial-gradient(circle at 100% 0, #1E1E1E 0, #1E1E1E 6px, transparent 6px) 0% 100%/8px 8px no-repeat,
      radial-gradient(circle at 0 0, #1E1E1E 0, #1E1E1E 6px, transparent 6px) 100% 100%/8px 8px no-repeat,
      linear-gradient(#1E1E1E, #1E1E1E) 50% 50%/calc(100% - 4px) calc(100% - 16px) no-repeat,
      linear-gradient(#1E1E1E, #1E1E1E) 50% 50%/calc(100% - 16px) calc(100% - 4px) no-repeat,
      linear-gradient(90deg, rgb(0 171 109 / 66%) 0%, transparent 74%) no-repeat;

  }
}