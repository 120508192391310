
.hintContainer {
  position: relative;

  .hint {
    display: flex;
    align-items: center;
    gap: 10px;
    font: 10px "Inter-Light", Arial, sans-serif;
    cursor: pointer;
    color: var(--color-font-gray);  

    &.mediumText {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--toogle-label-color);
      cursor: auto;
      white-space: pre-line;
    }

    span {
      white-space: pre-line;
    }
  }

  svg {
    flex: 0 0 auto;
  }

  .textWrapper {
    position: absolute;
    top: 25px;
    padding-top: 10px;
    border-radius: 20px;
    background: rgb(0 0 0 / 85%);
    backdrop-filter: blur(7.5px);
    max-height: 200px;
    overflow-y: auto;
    z-index: 3000;
    min-width: 250px;

    a {
      color: var(--color-main);
    }

    &.top {
      top: 25px;
      bottom: unset;
    }  
    
    &.topRight {
      top: 25px;
      right: 0;
      bottom: unset;
    }   

    &.bottom {
      top: unset;
      bottom: 25px;
    }
  }

  .text {
    padding: 10px 20px 20px;
    color: var(--white);
    font: 12px "Inter-Light", Arial, sans-serif;

    button {
      font: 12px "Inter-Light", Arial, sans-serif;
      color: var(--white);
      text-decoration: underline;

      &:hover {
        text-decoration: unset;
      }
    }
  }
}

.error {
  color: var(--color-red) !important;
  margin-top: -7px;

  
  svg path {
    stroke: var(--color-red);
  }
}

.warning {
  margin-top: 7px;

  svg path {
    stroke: #FF8605;
  }
}

.tooltipWrapper {
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: unset !important;

  --tooltip-bg: rgb(0 0 0 / 90%);
  --tooltip-radius: 16px !important;

  & > div {
    padding: 10px 5px;
    font: 12px "Inter-Light", Arial, sans-serif;

    a {
      text-decoration: underline;
      color: var(--white);

      &:hover {
        text-decoration: unset;
      }
    }
  }

  button {
    font: 12px "Inter-Light", Arial, sans-serif;
    color: var(--white);
    text-decoration: underline;

    &:hover {
      text-decoration: unset;
    }

  }
}

.hintIcon {
  opacity: 1;

  path {
    fill: var(--white) !important;
    fill-opacity: 1;
  }
}