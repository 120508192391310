.inputsWrapper {
  .gaInput {
    [class*="pinInput"] {
      input {
        background-color: var(--input-bg-color);
        border-radius: 8px;
        border: 1px solid var(--input-border-color);
        font-family: "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
      }
    }

    &.error {
      [class*="pinInput"] {
        input {
          background-color: var(--input-bg-color);
          border-radius: 8px;
          border: 1px solid var(--color-red) !important;
          color: var(--color-red) !important;
        }
      }
    }
  }
}