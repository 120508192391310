.input-form {
  position: relative;
  width: 100%;
  min-width: 100px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font: 14px 'Inter-Medium', Arial, sans-serif;
      color: var(--color-black);
    }

    button {
      font: 14px 'Inter-Medium', Arial, sans-serif;
      color: var(--color-main);
      border-bottom: 1px solid var(--color-main);

      @media (width <= 500px) {
        font-size: 12px;
      }

      &:hover {
        border-color: transparent;
      }
    }
  }

  &-content {
    margin-top: 7px;
  }

  p.comment {
    margin-top: 7px;
    font: 9px 'Inter-Light', Arial, sans-serif;
    color: var(--input-comment-color);
  }
}