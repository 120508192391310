.skeleton {
  width: 100%;
}

.darkStyle {
  border: none !important;

  &::before {
    background-color: #1E1E1E !important;
  }

  &::after {
    background-color: #242424 !important;
  }
}
