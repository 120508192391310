
.editSubAccount {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  min-height: 200px;

  .exchangeInput {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    font: 13px "Inter-Regular", Arial, sans-serif;
    border-radius: 8px;
    border: 0.5px solid var(--input-border-color);
    background: var(--input-bg-color);
    padding: 10px;

    img {
      width: 20px;
      filter: grayscale(1);
    }

    .shortName {
      color: #737373;
      font: 13px "Inter-Regular", Arial, sans-serif;
    }

    .shortIcon {
      margin-left: auto;
    }
  }
  
  .selectItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
  
    img {
      width: 22px;
    }
  }
  
  .modalActions {
    display: flex;
    gap: 10px;
  }

  .valueLength {
    font: 10px "Inter-Light", Arial, sans-serif;
    color: dimgray;

    &.maxLength {
      color: var(--color-red) !important;
    }
  }
}

html[data-theme='dark'] {
  .editSubAccount {
    .valueLength {
      color: var(--white);
    }
  }
}