.subAccountPageAdaptive {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 35px;
    flex: 1 1;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  .navigation {
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;

    &:hover {
      opacity: 0.7;
    }

    .navBtn {
      width: 40px;
      height: 40px;
      padding: 7px;
      border-radius: 50%;
      background-color: #1D1D1D;

      path {stroke: var(--white);}
    }

    p {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
    }
  }

  .content {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 10px;
      min-height: 42px;

      .subAccount {
        display: flex;
        align-items: center;
        gap: 7px;

        h2 {
          font: 16px "Inter-SemiBold", Arial, sans-serif;
        }

        svg {width: 15px;}
        img {height: 20px;}
      }
    }

    .balancesInfo {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      margin-bottom: 40px;

      .balanceWrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        .totalBalanceSum {
          display: flex;
          align-items: center;
          gap: 10px;
          font: 24px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);

          .symbol {
            font: inherit;
            color: inherit;
          }
        }

        .balanceTitle {
          display: grid;
          grid-template-columns: repeat(2, 0fr);
          grid-template-rows: 1fr;
          grid-column-gap: 10px;
          align-items: normal;
          width: max-content;
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: #737573;
        }

        .balanceAllTitle {
          display: flex;
          align-items: center;
          gap: 10px;
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: #737573;
        }

        span {
          font: 18px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }
      }

      .block {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .actions {
        display: flex;
        flex-direction: column;
        gap: 10px;

        svg {
          display: flex;
          width: 25px;
          height: 25px;
        }

        .replenishmentIcon path {
          stroke: #00C37C;
        }

        .transfersIcon path {
          stroke: #9747FF;
        }

        .actionsBlock {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}