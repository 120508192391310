.calculator {
  margin-top: 20px;

  .calculator-container {
    max-width: 100%;

    .instrument-name {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;

      .instrument-name-title {
        font: 16px "Inter-Medium",Arial, sans-serif;
        color: var(--color-black);
      }

      .max-leverage {
        font: 12px "Inter-Light",Arial, sans-serif;
        color: var(--color-black);
      }
    }

    .input-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 40px;
        height: 42px;
        background-color: var(--color-orange);
        color: var(--white);
        z-index: 2;
        border-radius: 8px;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        font: 13px "Inter-Regular",Arial, sans-serif;
        color: var(--color-black);
        background-color: var(--input-bg-color);
        border: 1px solid var(--input-border-color);
        border-radius: 16px;
      }
    }

    .slider-container {
      margin-top: 30px;

      .slider-line-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        cursor: pointer;
      }

      .slider-line {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 2px;
        border-radius: 80px;
        background-color: var(--input-border-color);
        cursor: pointer;

        .filled-part {
          width: 0%;
          max-width: 100%;
          height: 100%;
          background-color: var(--color-main);
          border-radius: 80px 0 0 80px;
        }

        .slider {
          position: absolute;
          top: -2.5px;
          left: -6px;
          margin-top: 0;
          width: 13px;
          height: 13px;
          cursor: pointer;

          &::after {
            content: '';
            position: relative;
            top: -4px;
            right: 1px;
            z-index: 10;
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--white);
            border: 7px solid var(--color-main);
            border-radius: 50%;
            transform: rotate(45deg);
          }
        }
      }

      .stops {
        position: relative;
        width: 100%;
        z-index: 3;

        div {
          position: absolute;
          top: 19px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: fit-content;

          span {
            font-size: 10px;
            position: absolute;
            color: var(--color-font-gray);
          }

          &::after {
            content: "◉";
            position: relative;
            top: 8.3px;
            left: -5px;
            display: block;
            color: var(--color-main);
            width: 0;
            height: 33px;
            margin-top: -25px;
            font-size: 11px;
            z-index: 0;
            pointer-events: none;
          }
        }
      }
    }

    .max-trade-sum {
      margin-top: 50px;

      @media (width <= 976px) {
        margin-top: 30px;
      }

      p {
        font: 14px "Inter-Light",Arial, sans-serif;
        color: var(--color-black);
        margin-bottom: 10px;
        max-width: 250px;
      }

      h1 {
        font: 16px "Inter-Bold",Arial, sans-serif;
        color: #00AB6D;
      }
    }
   
  }
}