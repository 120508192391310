.cardWrapper {
  margin-top: 10px;
  
  .title {
    font: 18px "Inter-SemoBold",  Arial, sans-serif;
    color: var(--color-black);
  }

  .divider {
    margin: 10px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 695px;
    overflow: auto;
    padding: 0 5px;

    .card {
      background: var(--input-bg-color);
      border-radius: 16px;
      padding: 20px;
      height: max-content;

      .header {
        margin-bottom: 20px;

        .bonuses {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font: 20px "Inter-Medium",  Arial, sans-serif; 
          color: var(--color-black); 
    
          img {
            width: 30px;
          }
        }

        time {
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }
      }

      .column {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        margin-bottom: 5px;

        span, p, time {
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }

        .typeWrapper {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}

.emptyTable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

html[data-theme='light'] {
  .card {
    background: var(--white) !important;
  }
}