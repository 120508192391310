.loginForm {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;

  [class*="hint_error"] {
    margin-top: -15px;
    align-items: center;
  }

  a {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  & .restoreButton {
    margin-top: -15px;
  }
}



