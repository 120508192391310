.informBox {
  width: max-content;
  max-width: 487px;
  padding: 25px 30px;
  background-color: #1C1C1C;
  border-radius: 8px;
  min-height: 230px;

  @media (width <= 976px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  h2 {
    color: var(--white);
    font: 15px "Inter-Medium", Arial, sans-serif;
    margin-bottom: 13px;
  }

  ul {
    max-width: 320px;
    padding-left: 20px;

    @media (width <= 976px) {
      width: 100%;
      max-width: 100%;
    }

    li {
      color: #4B4B4B;
      font: 13px "Inter-Regular", Arial, sans-serif;
      list-style: disc;

      &:not(:last-of-type) {
        margin-bottom: 13px;
      }

      &.active {
        color: var(--color-main);
      }
    }
  }
}
