.gaModalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
  

  .modalHint {
    display: flex;
    gap: 10px;

    svg {
      flex: 1 1 60px;

      path {
        stroke: #FF8605;
      }
    }

    p {
      font: 13px "Inter-Regular";
      line-height: 150%;
      color: var(--color-black);
    }
  }

  .info {
    font: 15px "Inter-Regular";
    margin-bottom: 25px;
    line-height: 150%;
    color: var(--color-black);

    a {
      color: var(--color-main);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .gaKeyWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 25px 0;
    
    .gaKey {
      font: 15px "Inter-Medium";
      color: var(--color-black);
    }
    
    button {
      display: flex;
    
      &:hover {
        svg path {
          stroke: var(--color-main);
        }
      }
    }
  }
  
  .gaModalActions {
    display: flex;
    width: 100%;
  }
}