.instrument-list-size-limiter {
  position: relative;
  height: 100%;
  max-height: 500px;  
  min-height: 500px;
  overflow-y: auto;
  padding: 0 5px 10px;

  @media (height >= 1501px) {
    max-height: 1100px;  
  }

  @media (height >= 1201px) and (height <= 1500px) {
    max-height: 900px;
  }  

  @media (height >= 951px) and (height <= 1200px) {
    max-height: 610px;  
    min-height: 610px; 
  }


  @media (height >= 900px) and (height <= 950px) {
    max-height: 555px;  
    min-height: 555px; 
  }

  @media (height <= 850px) {
    max-height: 500px;  
    min-height: 500px; 
  }

  .empty-position {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 0);
    width: calc(100% - 50px);
  }     
    
  .loading-position {
    position: absolute;
    top: 40%;
    right: 50%;
    transform: translate(50%, 40%);
    width: calc(100% - 50px);
  }

  table.instrument-list {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
  
    .tr-wrapper {
      background: #131313;
      border-radius: 8px;
    }
  
    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      outline: 2px solid var(--table-column-color);
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
          
      tr {
        vertical-align: inherit;
  
        &.active {
          outline: 1px solid var(--color-main);
        }
            
        th:first-child {
          border-bottom-left-radius: 8px;
          vertical-align: middle;
          width: 20px;
        }
  
        th:last-child {
          border-bottom-right-radius: 8px;
        }
  
        th:not(:last-child) {
          span {
            border-right: 1px solid #373737;
          }
        }
  
        th {
          background: var(--table-column-color);
          font: 11px 'Inter-Regular', Arial, sans-serif;;
          color: var(--table-column-text-color);
          text-align: left;
          padding: 10px;
  
          .checkbox {
            margin-left: 5px;
            margin-top: 3px;
            background: #242424;
  
            &.checkbox-disabled {
              pointer-events: none;
              opacity: 0.4;
  
              &::before {
                display: none;
              }
            }
          }
  
          span {
            display: flex;
            width: 100%;
            padding: 5px;
          }
        }
      }
    }
  
    tbody {
      tr {
        cursor: pointer;
        border-radius: 8px;
  
        &:hover, &.active {
          outline: 1px solid var(--color-main);
        }
  
        td {
          padding: 15px;
          font: 13px 'Inter-Regular', Arial, sans-serif;;
          color: #212121;
          box-sizing: border-box;
          vertical-align: middle;
  
          div {
            display: flex;
            align-items: center;
            gap: 10px;
          }
  
          img {
            width: 20px;
          }
  
          &:first-child {
            border-radius: 8px 0 0 8px;
          }
  
          &:last-child {
            border-radius: 0 8px 8px 0;
          }
                
          &:nth-child(2){
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
  
        &:nth-child(even) {
          background-color: var(--color-black-13);
  
          td {
            color: var(--color-black);
          }
        }
      }
    }
  }
}

.instruments-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 20px;
  height: calc(100% - 100px);
  font: 16px "Inter-Regular", Arial, sans-serif;;
  color: #737373;
}

.checkbox {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  background: var(--checkbox-bg-color);
  border-radius: 4px;
  transition: border-color 0.3s;
  border: 1px solid transparent;
  appearance: none;
  
  
  &:checked {
    background: var(--color-main);
  }
  
  &:checked::before {
    content: "✓";
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 12px "Inter-SemiBold", Arial, sans-serif;
  }
}

.loader {
  position: static !important;
  
  .mantine-LoadingOverlay-overlay {
    backdrop-filter: none !important;
    background: none !important;
    inset: auto !important;
  }
  
}