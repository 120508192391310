.personalDataForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;

  @media (width <= 976px) {
    margin-bottom: 0;
  }

  .inputInfo {
    font: 9px "Inter-Light";
    color: var(--color-font-gray);
    margin-top: -10px;
  }


  .formGroup {
    display: flex;
    gap: 10px;

    input {
      padding: 10px !important;
    }    
  }
  
  .hintBlock {
    margin: 0 auto;
  }

  [class*="hint_error"] {
    align-items: center !important;
  }
}