.balancesWrapper {
  .subAccount {
    .info {
      display: grid;
      grid-template-columns: 1fr 4fr;
      grid-template-rows: 1fr;
      grid-column-gap: 10px;

      .totalQuantity {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-right: 5px;  
        padding: 15px 35px;
        font: 13px "Inter-Regular";
        color: var(--color-black);
        border-radius: 60px;
        border: 1px solid #DFDFDF;

        span {
          font: 28px "Inter-Regular";
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      .accounts {
        display: flex;
        align-items: center;
        gap: 10px;

        .quantity {
          border-radius: 60px;
          padding: 15px 35px;
          border: 1px solid #DFDFDF;
          
          p {
            font: 13px "Inter-Regular";
            color: #3A3A3A;
            margin-bottom: 5px;
          }

          .balance {
            display: flex;
            align-items: center;
            gap: 5px;

            span {
              font: 18px "Inter-Regular";
              color: var(--color-black);
              white-space: nowrap;
            }
          }
           
        }
      }
    }
  }
}

html[data-theme='dark'] {
  .balancesWrapper {
    .totalQuantity {
      border-color: #343434 !important;
    }

    .quantity {
      border-color: #343434 !important;

      p {
        color: #6A6A6A !important;
      }
    }
  }
}