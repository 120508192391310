.loginForm {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;

  [class*="hint_error"] {
    margin-top: -15px;
    align-items: center;
  }

  .inputInfo {
    margin-top: -15px;
    font: 10px "Inter-Light", Arial, sans-serif;
    color: #737573;
  }

  a {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  & .restoreButton {
    margin-top: -15px;
  }
}



