.transferHistoryAdaptive {
  display: flex;
  flex-direction: column;
  width: 100%;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h3 {
      font: 18px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);
    }

    .filterBtn {
      cursor: pointer;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.9);
      }

      &:hover {
        path {
          stroke: var(--color-orange);
        }
      }

      path {
        stroke: var(--color-black);
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 820px;
    min-height: 150px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 20px;

    [class*="empty"] {
      margin: 0;
    }

    [class*='select-skeleton'] {
      margin-top: 0 !important;
    }
    
    .tableState {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 205px;
      max-height: 410px;
    }

    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }

    .transferItem {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: var(--card-bg-color);
      padding: 15px 25px;
      border-radius: 16px;
      width: 100%;

      .boxVertical {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
      }

      .box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .horizontalWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .lineTitle {
          color: var(--color-black);
          font: 13px "Inter-Regular", Arial, sans-serif;
        }

        img {
          width: 20px;
        }
      }

      .data {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .quantity {
          display: flex;
          gap: 5px;

          span {
            color: var(--color-black);
            font: 20px "Inter-Medium", Arial, sans-serif;
          }
        }

        .createdAt {
          color: var(--color-black);
          font: 13px "Inter-Regular", Arial, sans-serif;
        }
      }

      .subAccount {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          color: var(--color-black);
          font: 13px "Inter-Regular", Arial, sans-serif;
        }
      }
      
      .transferType {
        img {
          width: 35px;
        }
      }
    }
  }
}

.drawer {
  [class*="MuiPaper-root"] {
    padding: 35px 25px 10px !important;
  }

  .filterWrapper {
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 20px;
  
    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .closeBtn {
        &::before {
          background: var(--color-black);
        } 
        
        &::after {
          background: var(--color-black);
        }
      }

      h3 {
        font: 18px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .sortItemsContainer {
        max-height: 116px;
      }

      .typesItemsContainer {
        max-height: 185px;
      }

      .selectItem {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-black);
      }

      [class*="data-picker"] {
        max-width: 100%;
      }
    }
  }

  [class*="root-MuiModal-backdrop"] {
    backdrop-filter: blur(3px);
  }


  [class*="MuiPaper-root"] {
    background-color: var(--input-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px 25px;
    margin: 0 15px;
  }
}