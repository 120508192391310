.noAccount {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 350px;
  justify-content: center;
  
  @media (width <= 976px) {
    gap: 10px;
    width: 100%;
  }

  button {
    display: flex;
  }

  & p {
    color: var(--color-black-13);
    font: 13px "Inter-Regular";
    
    @media (width <= 976px) {
      color: var(--white);
    }
  }
}