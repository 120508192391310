.qrCodeWrapper {
  display: inline-flex;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--qr-code-bg-color);

  svg {
    width: 178px;
    height: 178px;
  }
}
