.toogler {
  display: flex;
  align-items: center;

  label.toogler-button {
    transition: .4s;

    &.active-toogle {
      transform: rotate(180deg);
      
      input:checked + span::before {
        transform: translateX(0);
      }
    }    
    
    &.disabled-toogle span {
      background-color: #00C37C54 !important;  

      &::before {
        background-image: url('../../../shared/assets//images/lockToogle.svg'); 
        background-size: 8px;
        background-position: center; 
        background-repeat: no-repeat;
        transform: rotate(180deg) !important;
      }
    }
  
    &.blocked-toogle {
      span {
        background-color: #787878 !important;  

        &::before {
          background-image: url('../../../shared/assets//images/lockToogle.svg'); 
          background-size: 8px;
          background-position: center; 
          background-repeat: no-repeat;
        }
      }
    }

    position: relative;
    display: inline-block;
    width: 34px;
    height: 19px;
    cursor: pointer;
    white-space: nowrap;
    flex: 0 0 34px;
    
    input {
      display:none;
    }

    span {
      position: absolute;
      border-radius: 34px;
      inset: 0;
      background-color: #2C2C2C;
      transition: .4s;

      &::before {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 2px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transition: .4s;
        background-color: var(--white);
      }
    }

    input:checked + span {
      background-color: #00C37C;
    }

    input:checked + span::before {
      transform: translateX(15px);
    }
  }

  label.toogler-content {
    cursor: pointer;

    p {
      font: 13px 'Inter-Medium', Arial, sans-serif;
      color: var(--toogle-label-color);
      margin-left: 8px;
    }
    
    &.blocked-toogle-label {
      p {
        color: #787878;
      }
    }
  }

  &.disabled-toggle{
    label.toogler-button span {
      background-color: #787878;
    }

    label.toogler-content  p {
      color: #787878;
    }
  }

  &.loading-toogle {
    pointer-events: none;
    
    span {
      opacity: 0.7;
    }
  }

  span.comment {
    display: flex;
    align-items: center;
    gap: 5px;
    font: 11px 'Inter-Light', Arial, sans-serif; 
    color: #737573;
    margin: 1px 0 0 10px;
  }
}