.trading-page-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;


  @media (width <=976px) {
    flex-direction: column;
    padding: 0 20px 20px;
    margin-top: 90px;

  }

  .trading-tables {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}
