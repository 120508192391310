.slider {
  margin-top: 25px;
  
  @media (width <= 976px) {
    margin-top: 5px;
  }

  .slider-items {
    position: relative; 
    display: flex;
    gap: 40px;
    overflow-x: auto;

    @media (width <= 976px) {
      gap: unset;
    }

    &::-webkit-scrollbar {
      display:none;
    }
  
    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: var(--input-border-color);
      z-index: 1;
    }

    .slider-item { 
      white-space: nowrap;
      font: 15px 'Inter-Medium', Arial, sans-serif;;
      color: var(--color-black);
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin-right: 25px;
      padding-bottom: 15px;
      position: relative;
      z-index: 2;
      border-color: var(--input-border-color);

      @media (width <= 976px) {
        margin-right: 0;
        padding-right: 45px;
      }

      &:hover {
        color: #00AB6D;
      }

      &_active {
        color: #00AB6D;
        border-color: #00AB6D;
      }
    }
  }

  .slider-content { 
    margin: 25px 0 0;
  }
}

.slide {
  .title {
    margin-bottom: 12px;
  }

  .description { 
    font: 13px 'Inter-Regular', Arial, sans-serif;;
    color: var(--color-black);
    margin-bottom: 30px;
    line-height: 1.5;

    @media (width <= 976px) {
      margin-bottom: 10px;
    }
  }
}

.tabs-container {  
  .tabs-navigation {
    // gap: 40px;
    flex-wrap: nowrap;
    margin-bottom: 20px;

    
    .tab-item {
      padding: 15px 0;
      padding-right: 40px;
      border-bottom: 2px solid var(--divider-color);

      &:nth-child(5) {
        padding-right: 0;
      }

      span { text-align: left;}

      &:hover {
        --tab-bg: unset;
      
        span {color: var(--color-main);}
      }

      &.visible {
        width: 100%;
        pointer-events: none;
        
        span {
          visibility: hidden;
          opacity: 0;
        
        }
      }
    }

    --tab-border-color: var(--divider-color);

    &::before {display: none;}

    @media (width <=976px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      -ms-overflow-style: none; /* Internet Explorer и Edge */
      scrollbar-width: none; /* Firefox */
  
      &::-webkit-scrollbar {
        display: none; /* Safari и Chrome */
      }
    }
  }
}
