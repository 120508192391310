.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;

  .title {
    font: 15px "Inter-regular", Arial, sans-serif;
    color: var(--color-black);
  }

  .info {
    font: 12px "Inter-regular", Arial, sans-serif;
    color: var(--color-black);
  }
}