.subContainer {
  header {
    display: flex;
    align-items: center;
    margin-right: auto;
    
    .navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 20px; 
      background-color: #0000009C;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: var(--color-black);
      }
    }

    .subAccountName {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: auto;

      img {
        width: 20px;
      }

      button {
        font: 26px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
      }

      .navigations {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 0 10px;
      }

      .navigationButton {
        white-space: nowrap;
        font: 26px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-font-gray);
      
        &.active {
          color: var(--color-black);
        }

        &:hover {
          color: var(--color-black);
        }
      }
    }
  }
}


html[data-theme='dark'] {
  .subContainer {
    .navigation {
      background-color: var(--color-black-27);

      &:hover {
        background: black;
      }
    }
  }
}
