.btn-link {
  display: flex;
  align-items: self-end;

  .loading-width {
    min-width: 63px;
  }

  .m-b34414df {
    width: 15px;
    height: 15px;

    &::after {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }
  }

  button {
    &.isActionDisabled {
      span {
        color: #A0A0A0;
        border-bottom: 1px solid #A0A0A0;
        white-space: nowrap;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8; 
    }

    span {
      font: 12px "Inter-Regular", Arial, sans-serif;
      color: var(--color-main);
      padding-bottom: 1px;
      cursor: pointer;
      border-bottom: 1px solid var(--color-main);
      white-space: nowrap;
      
      &:hover {
        border-color: transparent;
      }
    }
  }
}
