.four-ga-modal-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 0 20px;
  margin-bottom: -20px;

  svg {
    display: flex;
    width: 85%;
  }
  
  .four-modal-body {
    h1 {
      margin-bottom: 10px;
      font: 17px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);
    }

    .four-modal-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 5px;
      font: 13px "Inter-Regular", Arial, sans-serif;
      line-height: 150%;
      color: var(--color-black);
    }

    button {
      font: 15px "Inter-Regular", Arial, sans-serif;
      color: var(--color-main);
      border-bottom: 1px solid var(--color-main);

      &:hover {
        border: none;
      }
    }
  }
}

.resize-modal {
  .mantine-Modal-body {
    padding-bottom: 0;
  }

  .m-b5489c3c {
    position: absolute;
    right: 0;

    h2 {
      display: none;
    }
  }
}