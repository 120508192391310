.container {
  display: flex;
  height: 100vh;
  background: #0000004A;

  @media (width < 976px) {
    flex-direction: column;
    height: 100%;
  }

  .abstract {
    position: absolute;
    bottom: 0;
    left: 0;

    @media (width <= 976px) {
      display: none;
    }
  }
  
  .bodyWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex: 1 1 55%;
    padding: 65px 50px 65px clamp(3.125rem, -0.34rem + 5.43vw, 7.813rem);
  
    @media (width <= 976px) {
      padding: 10px 20px;
    }
  }
  
  .formWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 5px 10px 0 ;
    padding: 55px 20px 55px clamp(3.125rem, 1.277rem + 2.9vw, 5.625rem);
    height: calc(100% - 20px);
    flex: 1 1 45%;
    background-color: #F7F6F7;
    border-radius: 16px 0 0 16px;

    @media (width <= 976px) {
      background-color: transparent;
      margin: 0;
      flex: 1;
      border-radius: unset;
      height: 100%;
      padding: 0 20px 20px;
    }
  
    .formContainer {
      max-width: 360px;
      padding-right: 20px;
      overflow: auto;
      margin-top: auto;
      
      @media (width >= 1600px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      @media (width <= 976px) {
        width: 100%;
        max-width: 100%;
        padding: 0;
        overflow: unset;
      
        [class*="language-selection"] {
          display: none;
          
          button {
            background-color: var(--white) !important;
            color: black !important;
          }
        }
      }
      
      .text {
        font: 15px "Inter-Regular", Arial, sans-serif;
        color: #2C2C2C;
        max-width: 300px;
        margin-bottom: 20px;
        line-height: 1.2;


        @media (width <= 976px) {
          font: 12px "Inter-Regular", Arial, sans-serif;
          color: var(--color-font-gray);
        }
      }
    }
  }
}
  
  
  
  