.balanceAccounts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px 35px;
  width: 100%;
  height: 200px;
  background: linear-gradient(227deg, #131313 0%, #2B2B2B 100%);
  border-radius: 16px;
  
  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  
    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--white);
      font: 16px "Inter-Regular", Arial, sans-serif;
  
      svg {
        width: 19px;
        height: 19px;
        cursor: pointer;
  
        path {
          stroke: gray;
        }
      }
    }
  
    .info {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    .case {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px;
      background: rgb(255 255 255 / 15%);
      border-radius: 8px;
      cursor: pointer;
    
    }
  }
  
  .sumWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  
    .sum {
      color: var(--white);
      font: 25px "Inter-SemiBold", Arial, sans-serif;
    }

    .error {
      color: var(--color-red);
      font: 20px "Inter-Medium", Arial, sans-serif;
      max-width: 200px;
    }
  
    .info {
      display: flex;
      color: var(--white);
      font: 13px "Inter-Regular", Arial, sans-serif;
      width: max-content;
      cursor: pointer;

      &.plus {
        color: var(--color-main) !important;
      }  
      
      &.minus {
        color: var(--color-red);
      }
    }    

    .loaderWrapper> div:first-child  {
      justify-content: flex-start;
    }
  }

  .pnlUsdt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin: auto 0 0 auto;
    background: rgb(255 255 255 / 15%);
    border-radius: 16px;
    width: max-content;

    &.plus {
      background: linear-gradient(352deg, #1E1E1E 0%, #004E31 100%);
    }  
    
    &.minus {
      background: linear-gradient(175deg, #FE3944A6 0%, #2B2B2B78 90%);
    }

    span {
      font: 12px "Inter-Regular", Arial, sans-serif;
      color: var(--white);
    }
  }

  .balanceFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    .title {
      font: 14px "Inter-Regular";
      color: #737573;
    }
  }
}