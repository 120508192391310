.alerts {
  position: fixed;
  top: 30px;
  right: -310px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 300px;
  height: max-content;
  max-height: calc(100% - 50px);
  overflow: auto;
  padding: 0 25px;
  overflow-x: hidden;
  z-index: 5000;
  transition: all 0.1s ease, opacity 0.1s ease; 
  -ms-overflow-style: none; /* Internet Explorer и Edge */
  scrollbar-width: none; /* Firefox */
  
  &::-webkit-scrollbar {
    display: none; /* Safari и Chrome */
  }

  @media (width <= 500px) {
    top: 15px;
    max-width: 100%;
    padding: 0 20px;
  }

  &.show {
    right: 0;
  }

  .alert {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1.5px solid transparent;
    min-width: 200px;
    box-sizing: border-box;
    background: rgb(17 21 20 / 70%);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    transition: all 0.3s ease, opacity 0.3s ease; 
    animation-name: transitions;
    animation-duration: 0.3s;
    animation-direction: alternate;

    &.show {
      opacity: 1;
    }

    &.success {
      border-color: var(--color-main);
    }

    &.warning {
      border-color: var(--color-orange);
    }

    &.error {
      border-color: var(--color-red);
    }

    svg {
      min-width: 16px;
      min-height: 16px;

      & path {
        stroke: var(--white);
      }
    }

    .alert-content {
      padding: 0 10px;

      p {
        font: 13px 'Inter-Regular', Arial, sans-serif;
        color: var(--white);
        word-wrap: break-word;
        max-width: 170px;

        @media (width <= 576px) {
          max-width: 100%;
        }
      }
    }

    .btn-close {
      margin-left: auto;

      &::before, &::after {
        width: 15px;
        height: 1.5px;
        background: var(--white);
      }
    }
  }
}

.show {
  right: 0 !important; 
}

@keyframes transitions {
  0% {
    transform: translate(300px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}