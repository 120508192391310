.documents-slide {
  h4 {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
    margin-bottom: 25px;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 715px;

    a {
      display: flex;
      align-items: center;
      gap: 10px;
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--white);
      background-color: #2C2C2C;
      border-radius: 8px;
      padding: 15px;

      &:hover {
        color: var(--color-main);

        svg {
          path, polyline, line {
            stroke: var(--color-main);
          }
        }
      }
    }
  }
}