.policyWrapper {
  padding: 0 20px;

  .hintText {
    max-width: 200px;
  }

  .actions {
    display: flex;
    gap: 10px;
    margin-top: 30px;

    svg {
      width: 15px;
    }
  }
}