.passport {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 315px;
  text-align: center;

  &.horizontal {
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
    margin-right: auto;
    max-width: 100%;
    text-align: left;

    .passportBody {
      align-items: flex-start;
    }

  }

  .passportBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
    
  .title {
    font: 16px "Inter-Medium", Arial, sans-serif;
    color: var(--color-black);
  }

  .info {
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: #737573;
    margin-bottom: 10px;
    max-width: 300px;
  }

  .verificationStatus { 
    display: flex;
    align-items: center;
    margin-right: 10px;
    border-radius: 8px;
    background-color: #2C2C2C;
    font: 12px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
    transition: background-color 0.2s ease;

    .status-text {
      font: 13px 'Inter-Regular', Arial, sans-serif;
      margin-left: 6px;
      padding: 10px 15px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: var(--white);
      cursor: pointer;
      height: 42px;

    }

    &.created {
      background: #00C37C;
      color: var(--color-white);

      &:hover {
        background-color: #00A468;
      }
    }

    &.process {
      padding: 10px 15px;
      background: #FF8605;
      color: var(--white);
    }

    &.verified {
      padding: 10px 15px;
      background: #2C2C2C;
      color: var(--white);
    }

    &.noVerified {
      background: #00C37C;
      color: var(--white);

      &:hover {
        background-color: #00A468;
      }
    }
  }
}