.profile {
  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
  }

  .section-content {
    flex-direction: column;

    .public-info {
      background: none;
      padding: 0;

      h3 {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .slider {
      width: 100%;
      background: none;
      padding: 0;
    }
  }
}

.section-name {
  .btn {
    max-width: 104px;
  }

  .section-name-title {
    text-transform: none;
    letter-spacing: 1px;
  }

  .section-user-id-copy {
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;
    
    .user-id-title {
      font: 13px "Inter-Medium", Arial, sans-serif;
      color: var(--color-black);

      @media (width <= 400px) {
        display: none;
      }
    }

    .user-id-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--input-border-color);
      padding: 5px 10px;
      padding-left: 15px;

      .user-id-value {
        font: 12px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
      }
    }

  }
}

.public-info-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: transparent !important;
  width: 100% !important;
  padding: 0 !important;
  min-height: 150px;

  [class*="m_17884d0f"] {
    --carousel-height: 12rem !important;
  }  
  
  [class*="mantine-Carousel-indicator"] {
    &[data-active] {
      width: 50px;
      background-color: #FF8605 !important;
    }
  
    button {
      background: var(--input-border-color);
      opacity: 1 !important;
      width: 16px;
      height: 6px;
      transition: width 250ms ease;
    }
  }  
  
  [class*="mantine-Carousel-indicators"] {
    inset-inline-end: unset;
    justify-content: left !important;
  }  
  
  [class*="mantine-Carousel-viewport"] {
    overflow: hidden;
  }

  @media (width <= 1100px) {
    flex-direction: column;
    gap: 20px;
  }

  .rebate-title {
    font: 18px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
  }

  .public-info { 
    min-width: 300px;
    margin-right: 10px;

    h3 { 
      font: 18px 'Inter-Medium', Arial, sans-serif;
      color: var(--color-black);
    }
  
    span.user-role { 
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: var(--color-black);
      margin-top: 8px;
      display: block;
    }
  
    .socials {
      max-width: fit-content;
      margin-top: 15px;
  
      input {
        outline: none;
        background: none;
        border-radius: 0;
        border: none;
        padding: 0;
      }
    }
  }

  .public-info-widget {
    display: flex;
    gap: 10px;

    @media (width <= 967px) {
      display: block;
      width: 50%;
    }   
    
    @media (width <= 500px) {
      width: 100%;
    }
  }
}

.verification-form-wrapper {
  padding: 10px 20px;
}

.verification-input {
  margin-bottom: 20px;

  .verification-input-text {
    color: var(--color-black);
    font: 10px "Inter-Light", Arial, sans-serif;
    line-height: 1.2;
    display: flex;
    padding-top: 10px;
  }
}

.verify-modal-action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 115px);
  font: 16px "Inter-Medium", Arial, sans-serif;
  color: #737573;
  text-align: center;
}