.rebateItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--card-bg-color);
  padding: 25px;
  border-radius: 16px;
  width: 100%;

  .boxVertical {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 5px;
  }

  .box {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    
    span, div, p {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
    }
  }

  .horizontalWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;

    .lineTitle {
      color: var(--color-black);
      font: 13px "Inter-Regular", Arial, sans-serif;
    }
  }

  .exchangeImage {
    width: 35px;
  }

  .quantity {
    font: 20px "Inter-Medium", Arial, sans-serif;
    color: var(--color-black);
  }

  time {
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .quantity {
      display: flex;
      gap: 5px;

      span {
        color: var(--color-black);
        font: 20px "Inter-Medium", Arial, sans-serif;
      }
    }

    .createdAt {
      color: var(--color-black);
      font: 13px "Inter-Regular", Arial, sans-serif;
    }
  }

  .subAccount {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      color: var(--color-black);
      font: 13px "Inter-Regular", Arial, sans-serif;
    }
  }
      
}