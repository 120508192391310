.subAccountsWrapper {
  display: flex;
  gap: 10px;

  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 35px;
    flex: 1 1;
    border-radius: 16px;
  }

  .aside {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 0 1 350px;
    min-width: 350px;
  }

}
