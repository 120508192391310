@mixin shared-styles {
  display: inline-flex;
  width: 50px;
  background: var(--color-main);

  &:hover {
    box-shadow: 0 0 10px var(--color-main);
    transform: scale(1.03);
  }
}

.authBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 10;

  @media (width <= 976px) {
    [class*="bodyFooter"] {
      display: none;
    }
  }
}
    
.logotype {
  max-width: 200px;

  [class*="language-selection"] {
    display: none;

    button {
      background-color: var(--white) !important;
      color: black !important;
    }
  }

  @media (width <= 976px) {
    margin-bottom: 65px;
    
    svg {
      display: flex;
      width: 110px;
    }

    [class*="language-selection"] {
      display: block;
    }
  }
}
  
.title {
  margin-bottom: 25px;
  font: 42px "Inter-Semibold", Arial, sans-serif;
  color: var(--white);

  span {
    color: var(--color-main);
  }
  
  @media (width <= 1440px) {
    font-size: 32px;
  }

  @media (width <= 976px) {
    margin-bottom: 10px;
    font-size: 28px;
  }
}

.descroption {
  margin-bottom: 40px;
  max-width: 400px;
  color: var(--white);
  font: 18px "Inter-Regular", Arial, sans-serif;
  
  @media (width <= 1440px) {
    font-size: 14px;
    margin-bottom: 30px;
  }

  @media (width <= 976px) {
    max-width: 280px;
    margin-bottom: 20px;
    font-size: 13px;
    
  }
}
  
.slider {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 40px;
  
  @media (width <= 1440px) {
    margin-bottom: 30px;
  }
  
  button {
    display: inline-flex;
    width: 15px;
    height: 6px;
    background: var(--white);
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease;
  
    &:hover {
      box-shadow: 0 0 5px var(--white);
      transform: scale(1.03);
    }
  }
}  

.first button:nth-child(1)  {
  @include shared-styles;
}

.second  button:nth-child(2)  {
  @include shared-styles;
}

.third button:nth-child(3) {
  @include shared-styles;
}

.fourth button:nth-child(4)  {
  @include shared-styles;
}