.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: calc(100% - 55px);
  min-width: 350px;
  margin: auto;

  @media (width <= 976px) {
    flex-direction: column-reverse;
    gap: 0;
  }

  &.error {
    flex-direction: column-reverse;

    .hamsterWrapper {
      .see {
        display: block;
      }

      .notSee {
        display: none;
      }


      &:hover {
        .see {
          display: none;
        }

        .notSee {
          display: inline-block;
        }
      }

    }
    
    p {
      width: 100%;
      max-width: 280px;
      min-width: 250px;
      height: unset;
    }

    img {
      width: 150px;
      height: 150px;
    }
  }

  &.horizontal {
    flex-direction: unset;

    @media (width <= 500px) {
      flex-direction: column-reverse;
    }

    img {
      width: 70px;
      height: 70px;
    }
  }
    
  p {
    display: flex;
    align-items: center;
    height: 100px;
    font: 16px "Inter-Medium", Arial, sans-serif;
    color: #737573;
    text-align: center;

    @media (width <= 976px) {
      max-width: 200px;
      font-size: 12px;
      height: unset;
    }
  }

  img {
    width: 100px;
    height: 100px;
  }
}