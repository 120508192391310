aside {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  
  &::-webkit-scrollbar {
    display: none;  /* Safari и Chrome */
  }
}

.aside-menu {
  margin-bottom: 20px;
  cursor: pointer;

  nav {
    display: flex;
    flex-direction: column;

    .menu-item {
      display: flex;
      align-items: center;
      height: 40px;
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: var(--white);
      text-decoration: none;
      margin-top: 3px;
      padding-left: 10px;
      border-radius: 8px;
      background-color: #1E1E1E;
      cursor: pointer;

      svg {
        transition: transform 0.3s ease;

        path {stroke: var(-white);}
      }

      &:first-child {
        margin-top: 8px;
      }

      &_active {
        svg {
          animation-name: shrink-and-expand;
          animation-duration: 0.6s;
          animation-direction: alternate;
        }
      }

      &_active, &:hover {
        background: linear-gradient(90deg, #1E1E1E 0%, #004E31 100%);
        color: var(--white);
        cursor: pointer;

        svg path {
          stroke: #00AB6D;
        }
      }

      span {
        margin-left: 12px;
      }
    }
  }
}

@keyframes shrink-and-expand {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9); 
  }

  100% {
    transform: scale(1);
  }
}


html[data-theme='light'] {
  @media (width <= 976px) {
    .aside-menu {
      .menu-item {
        background-color: var(--white);
        color: var(--color-black-15);
  
        svg path {
          stroke: var(--color-black-1e);
        }
  
        &_active, &:hover {
          background: linear-gradient(90deg, #1E1E1E 0%, #004E31 100%);
          color: var(--white);

          svg path {stroke: var(--color-main);}
        }
      }
    }
  }
}

html[data-theme='dark'] {
  @media (width <= 976px) {
    .aside-menu {
      .menu-item {
        &_active, &:hover {
          background: linear-gradient(90deg, #1E1E1E 0%, #004E31 100%);
          color: var(--white);
        }
      }
    }
  }
}