.instrument-settings {
  background: var(--table-bg-color);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px;

  .marge-item {
    padding: 10px;
  }

  .instrument-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    .btn {
      margin-top: 0 !important;
    }
  }        
}

.marge-item {
  padding: 10px;
}