.formGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  color: black;
  gap: 10px;
  max-width: 350px;


  @media (width <= 976px) {
    width: 100%;
    max-width: 100%;
  }
  
  label {
    color: var(--color-black);
    font: 14px 'Inter-Medium', Arial, sans-serif;
  }
  
  input {
    position: relative;
    width: 100%;
    height: 42px;
    border: 1px solid var(--input-border-color);
    color: var(--color-black);
    background-color: transparent;
    padding: 0 110px 0 15px;
    border-radius: 10px;
    font-size: 13px; 

    @media (width <= 976px) {
      max-width: 100%;
    }

    &::placeholder {
      font-size: 13px; 
    }

    &:focus {
      font-size: 13px; 
      border-color: var(--color-main);
    }
  }

  img {
    position: absolute;
    right: 15px;
    bottom: 12px;
    display: flex;
    width: 20px;
  }
}

.inputContainer {
  width: 100%;

  .inputWrapper {
    position: relative;
  }

  button {
    position: absolute;
    right: 15px;
    bottom: 12px;
    display: flex;
    
    &:hover {
      border-bottom: none;
    }

    &:focus {
      border-bottom: none;
    }
  }

  time {
    position: absolute;
    right: 15px;
    bottom: 13px;
    color: var(--color-main);
    font: 12px "Inter-Light", Arial, sans-serif;
  }
}

.viewPassword {
  position: absolute;
  right: 10px;
  bottom: 15px;
  display: flex;
  cursor: pointer;

  svg  {
    width: 15px;
    height: 15px;

    path {
      stroke: var(--color-font-gray);
    }

    &:hover path{
      stroke: var(--color-main);
    }
  }
}

.error {
  input {
    border-color: var(--color-red) !important;
  }
}

.select {
  position: relative;

  .label {
    color: var(--color-black);
    font: 14px "Inter-Medium", Arial, sans-serif;
    margin-bottom: 10px;
  }

  .selectContainer { 
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid var(--input-border-color);
    background-color: transparent;
    color: #797474;
    padding: 0 15px;
    border-radius: 10px;
    font-size: 13px;
  
    .selectForm {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px; 
      cursor: pointer;
  
      svg {
        width: 16px;
        height: 16px;
      }
    }
    
    .selectGroup {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .selectTitle {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: var(--color-main);
      }

      time {
        font: 12px "Inter-Light", Arial, sans-serif;
        color: var(--color-main);
      }
    }
  }

  .list {
    position: absolute;
    width: 100%;
    max-width: 350px;
    background-color: var(--input-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: 10px;
    margin-top: 5px;

    @media (width <= 976px) {
      max-width: 100%;
    }

    ul {
      position: relative;
      background: var(--color-background);
      z-index: 2000;
      padding: 5px 0;
      border-radius: 8px;


      li {
        cursor: pointer;
        padding: 10px 15px;
        margin: 0 5px;
        transition: all 0.3s ease;
        border-radius: 10px;
        outline: none;
      

        &:hover ,
        &:focus {
          background-color: var(--color-orange);
          color: var(--color-white);
          border-radius: 10px;
          margin: 0 5px;
        }

        button {
          cursor: pointer;
          color: inherit;
          width: 100%;
          text-align: left;
        }
      }
  
      li:not(:last-child) {
        border-bottom: 1px solid var(--input-border-color); 
      }
      
    }
  }
}

.active {
  border-color: var(--color-main) !important;

  button svg {
    transform: rotate(180deg);
  }
}
