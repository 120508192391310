.gaModalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;

  .info {
    font: 15px "Inter-Regular";
    margin-bottom: 25px;
    line-height: 150%;
    color: var(--color-black);
  }

  .gaModalActions {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 25px;
  }
}