.gaModalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;

  .info {
    font: 15px "Inter-Regular";
    margin-bottom: 25px;
    line-height: 150%;
    color: var(--color-black);
  }

  .downloadLinksWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    .downloadLink {
      display: flex;
      align-items: flex-start;
      gap: 11px;
      width: 100%;
      border-radius: 8px;
      background-color: #F5F5F5;
      padding: 10px 15px;
      color: var(--color-black);
      font: 13px "Inter-Regular";
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #EAE8E8;
      }
    }
  }

  .gaModalActions {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 25px;
  } 
}

html[data-theme='dark'] {
  .gaModalWrapper {
    .downloadLink {
      background-color: transparent;

      &:hover {
        background-color: var(--color-black-1e);
      }
    }
  }
}