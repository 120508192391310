@mixin font-regular {
  font: 13px "Inter-Regular", Arial, sans-serif;
  color: var(--color-black);
}

.instrumentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--input-bg-color);
  border: 1px solid var(--input-border-color);
  padding: 15px;
  border-radius: 8px;
  transition: transform 0.2s ease;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  .instrument {
    display: flex;
    align-items: center;
    gap: 10px;

    img {height: 20px;}

    h2 {
      @include font-regular;
    }
  }

  .brackets {
    display: flex;
    align-items: center;
    gap: 10px;

    .maxLeverage {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: #737573;
    }
    .choosenBracket { @include font-regular;}
  }
}