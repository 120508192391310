.section-content {
  .tabs-wrapper {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 0;

    .tabs-nav {
      margin-bottom: 30px;

      button:hover {
        border-color: transparent;
        
        span {
          color: var(--color-main);
        }
      }

      &::before {
        border-color: var(--input-border-color) !important;
      }

      .mantine-Tabs-tab:hover {
        background: unset;
      }

      .mantine-Tabs-tab:first-child {
        padding-left: 0;
        justify-content: flex-start;
      } 
      
      .mantine-Tabs-tab:last-child {
        padding-right: 0;
        justify-content: flex-end;
      }
    }

    .tab-content {
      & > div {
        background-color: var(--table-bg-color);
        border-radius: 8px;
        padding: 30px;
        box-sizing: border-box;
    
        &.replenishment {
          .item-content {
            width: 100%;
          }
        }
    
        &.withdrawal-wrapper {
          min-width: 425px;
          
          .withdrawal-sum,
          .user-white-wallets {
            .input-form-title .btn-link {
              margin-left: auto;
            }
    
            .add-wallet {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-bottom: 30px;
              
              .input-form {
                margin-top: 0;
              }
            }
    
            .divisors {
              margin-top: 10px;
            }
    
            .input-text {
              display: flex;
              align-items: center;
              max-height: 42px;
              justify-content: space-between;
              background-color: var(--input-bg-color);
              border-radius: 8px;
              border: 0.5px solid var(--input-border-color);
              box-sizing: border-box;
              outline: none;
    
              input {
                width: 100%;
                box-sizing: border-box;
                outline: none;
                font: 13px "Inter-Regular", Arial, sans-serif;
                border: none;
                padding: 12px;
              }
            }
          }
    
          .withdrawal-result {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
    
            @media (width <= 600px) {
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
            }
    
            .commissions {
              p.sum-to-receive {
                font: 13px 'Inter-Regular', Arial, sans-serif;
                color: var(--color-black);
              }
    
              p.network-comission {
                font: 12px 'Inter-Regular', Arial, sans-serif;
                color: #737573;
                margin-top: 3px;
              }
    
              p {
                span {
                  margin-left: 4px;
                }
              }
            }
    
            .btn {
              max-width: 178px;
    
              @media (width <= 600px) {
                max-width: 100%;
              }
            }
          }
        }
    
        .input-form {
          // margin-top: 25px;
        }
    
        .instruments {
          display: flex;
          justify-content: space-between;
    
          @media (width <= 600px) {
            flex-direction: column;
          }
    
          .input-form {
            width: 100%;
    
            @media (width <= 600px) {
              width: 100%;
            }
    
            .select {
              .instrument, .network {
                display: flex;
                align-items: center;
                padding: 10px;
    
                img {
                  width: 20px;
                  height: 20px;
                }
        
                span {
                  margin-left: 8px;
        
                  &.full-name {
                    font: 10px 'Inter-Light', Arial, sans-serif;
                    color: #737573;
                  }
                }
              }
            }
          }  
        }
    
        .advice-paragraph {
          margin-top: 20px;
          max-width: 400px;
    
          @media (width <= 600px) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}