
.addSubAccount {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  min-height: 200px;
  
  .selectItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
  
    img {
      width: 22px;
    }
  }
  
  .modalPolicy {
    display: flex;
    align-items: flex-start;
    
    p {
      font: 11px "Inter-Light";
      color: var(--color-black);

      strong {
        text-decoration: underline;
        font-weight: 800;
      }
    }
  }
  
  .modalActions {
    display: flex;
    gap: 10px;
  }

  .valueLength {
    font: 10px "Inter-Light", Arial, sans-serif;
    color: dimgray;

    &.maxLength {
      color: var(--color-red) !important;
    }
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  background: #D9D9D9;
  border-radius: 4px;
  transition: border-color 0.3s;
  appearance: none;
  margin-right: 10px;
  flex: 0 0 15px;


  &:checked {
    background: var(--color-main);
  }

  &:checked::before {
    content: "✓";
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 12px "Inter-SemiBold";
  }
}

html[data-theme='dark'] {
  .addSubAccount {
    .valueLength {
      color: var(--white);
    }
  }
}

.inputBorderColor input[type='text'] {
  border-color: var(--color-main);
}

.isEmpty input[type='text'] {
  border-color: var(--color-red);
}
