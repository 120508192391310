.authTitle {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: var(--color-black-13);
  font: 26px 'Inter-SemiBold', sans-serif;
  max-width: 350px;
  margin-bottom: 30px;

  h3 {
    max-width: 70%;
  }

  @media (width <= 976px) {
    color: var(--white);
    margin: 0 0 20px;
    max-width: 100%;
  }
}
