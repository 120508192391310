.container {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  min-width: 250px;
  width: 100%;
  

  &.binance {
    background: linear-gradient(47deg, #302200 0%, #100B00 100%);
  }

  &.bybit {
    background: linear-gradient(47deg, #15192A 0%, #1E1E1E 100%);
  }

  &.bingX {
    background: linear-gradient(90deg, #000 0%, #112059 100%);
  }

  .navigation {
    ul {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;

      li {
        cursor: pointer;

        button {
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: gray;

          &:hover {
            color: var(--white);
          }

          &.active {
            color: var(--white);
          }
        }
      }
    }
  }

  .rebatePercent {
    display: flex;
    width: 100%;
    color: var(--white);
    font: 29px "Inter-Light", Arial, sans-serif;
    margin-bottom: 10px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    font: 13px "Inter-Light", Arial, sans-serif;
  }

  .exchangeImage {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 30px;
  }
}