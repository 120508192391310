.data-picker-container {
  ul {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 7px;

    li {
      font: 11px "Inter-Medium", Arial, sans-serif;
      color: var(--color-black);
      transition: color 0.3s ease;
      cursor: pointer;

      &:hover {
        color: var(--color-main);
      }
    }
  }
}

.data-picker {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100%;
  min-width: 250px;
  max-width: 265px;
  border-radius: 8px;
  border: 1px solid #3A3A3A;
  background: #212121; 

  .react-datepicker-popper {
    z-index: 200;
  }

  &.white-mode {
    border: 1px solid #E8E8E8;
    background: #F7F6F7;

    input {
      color: var(--color-black) !important;
    }

    svg path{
      stroke: #A8A8A8;
    }
  }

  .react-datepicker-wrapper {
    width: 100%; 

    .react-datepicker__input-container {
      display: flex;

      input {
        width: 100%;
        border: none;
        background: transparent;
        color: var(--color-black);
        font: 13px "Inter-Regular", Arial, sans-serif;
      }
    }
  }

  .react-datepicker-popper {
    .react-datepicker {
      background-color: var(--table-bg-color);
      border: none;

      .react-datepicker__triangle {
        &::after {
          border-bottom-color: var(--table-bg-color);
          border-top-color: var(--table-bg-color);
        }
      }

      .react-datepicker__header {
        background-color: var(--table-bg-color);
      
        .react-datepicker__current-month {
          color: var(--color-black);
        }

        .react-datepicker__day-name {
          color: var(--color-black);
        }
      }
      
      & > div {
        font-family: "Inter-Regular", Arial, sans-serif;
      }

      .react-datepicker__month-container {
        .react-datepicker__current-month {
          font: 12px "Inter-Regular", Arial, sans-serif;
          text-transform: uppercase;
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              color: var(--color-black);
            }

            .react-datepicker__day--keyboard-selected {
              background-color: #00AB6D61;
              color: var(--white);
            }

            .react-datepicker__day--in-range {
              background-color: var(--color-main);
              color: var(--white);
            }
            
            .react-datepicker__day:hover {
              background-color: #00AB6D5E;
              color: var(--white);
            }


            .react-datepicker__day--in-selecting-range {
              background-color: #00AB6D5E !important;
            }
          }
        }
      }
    }
  }

  .react-datepicker__close-icon {
    &:hover {
      &::after {
        color: var(--color-main);
      }
    }

    &::after {
      background-color: unset;
      font-size: 18px;
      padding: 0;
      transition: color 0.3 ease;
    }
  }

  .react-datepicker__close-icon {
    &::after {
      color: var(--color-black);
    }
  }
}