.container {
  background-color: var(--color-white);
  border-radius: 16px;
  padding: 30px;
  min-width: 270px;

  @media (width <= 976px) {
    flex-direction: column-reverse;
    background-color: var(--card-bg-color);
  }

  [class*="toogler-content"] {
    p {
      color: var(--color-black) !important;


      @media (width <= 976px) {
        color: var(--color-black) !important;
      }
    }
  }

  .info {
    margin-top: 10px;
    color: var(--color-black);
    font: 11px "Inter-Light", Arial, sans-serif;
    line-height: 1.3;
    white-space: pre-line;

    @media (width <= 976px) {
      color: var(--color-black);
    }
  }

  .exchangesPreview {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;

    .exchange {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 0;
      border: 1px solid transparent;
      border-radius: 8px;

      img {
        width: 20px;
        height: 20px;
      }

      .exchangeTitle, .value {
        font: 13px "Inter-Regular", Arial,sans-serif;
        color: var(--color-black);
      }

      .value {
        margin-left: auto;
      }
    }
  }
}

.title {
  font: 25px "Inter-SemiBold", Arial, sans-serif;
  margin-bottom: 15px;
  color: var(--color-black);

  @media (width <= 976px) {
    color: var(--color-black);
  }
}

.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font: 13px "Inter-Light", Arial, sans-serif;
  margin-bottom: 10px;
  color: var(--color-black);

  @media (width <= 976px) {
    color: var(--color-black);
  }

  span {
    white-space: nowrap;
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);

    @media (width <= 976px) {
      color: var(--color-black);
    }
  }
}

.convertButton {
  background-color: #007BFF;
  color: var(--color-black);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;

  @media (width <= 976px) {
    color: var(--color-black);
  }
}

.manualConservation {
  margin-top: 20px;

  .exchanges {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;

    .exchangeItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      background-color: var(--input-bg-color);
      border: 1px solid transparent;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;

      &.selected {
        border-color: var(--color-main);
      }

      &:hover {
        border-color: var(--color-main);
      }

      &:focus-visible {
        border-color: var(--color-main);
      }

      &:active {
        transform: scale(0.99);
      }

      img {
        width: 20px;
        height: 20px;
      }

      .exchangeTitle, .value {
        font: 13px "Inter-Regular", Arial,sans-serif;
        color: var(--color-black);
      }

      .value {
        margin-left: auto;
      }
    }
  }

  .manualConservationTitle {
    font: 13px "Inter-Regular", Arial,sans-serif;
    color: var(--color-black);
  }

  .quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    background: var(--input-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    margin: 10px 0;
  
    input {
      color: var(--color-black);
      font: 13px "Inter-Regular", Arial, sans-serif;
      background: transparent;
      border: none;
      outline: none;
      width: 100%;
    }
  
    button {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-main);
      border-bottom: 1px solid var(--color-main);
  
      &:hover {
        border-color: transparent;
      }

      &:active {
        transform: scale(0.92);
      }
    }
  }

  .manualConservationHint {
    margin-top: 10px;
    font: 12px "Inter-Light", Arial,sans-serif;
    color: #737573;
  }
}

