.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 75px;
  margin-bottom: 20px;
  background-color: transparent;
  box-sizing: border-box;
  z-index: 2;
  padding: 20px 0 0;
  
  @media (max-width: 976px) {
    margin: 0;
    position: fixed;
    background: var(--color-background);
    z-index: 2002;
    padding: 0 20px;
  }

  .header-logo-mobile {
    display: none;
    cursor: pointer;

    img {
      width: 120px;
    }

    @media (max-width: 976px) {
      display: flex;
      width: 100px;
      margin-left: 10px;
    } 
  }

  .burger-menu {
    --burger-color: var(--color-black);
  }

  nav {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 976px) {
      display: none;
    }

    a { 
      position: relative;
      padding-bottom: 4px;
      margin: 6px 40px 0 0;
      font: 14px 'Inter-Regular', Arial, sans-serif;
      color: var(--color-black);
      border-bottom: 1px solid transparent;
      box-sizing: border-box;
      text-decoration: none;

      &.active::before {
        width: 100%;
      }
          
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #00C37C;
        transition: width 0.2s ease;
      }
    
      &:hover::before {
        width: 100%;
      }
    
      &:last-child {
        margin-right: 0;
      }
    
    }
  }

  .page-customisations {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;


    button {
      position: relative;
      display: flex;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {        
        line, path, polyline{
          stroke: var(--color-main);
        }
      }
    }

    svg {
      display: flex;
      transition: all 0.2s ease; 

      line, path, polyline{
        stroke: var(--color-black);
      }
    }

    .close-positions-btn {
      cursor: pointer;
      transition: all 0.2s ease;
      
      &:active {
        transform: scale(0.9);
      }

      &:hover {
        path {
          stroke: var(--color-main);
        }
      }
      
    }

    .indicator {
      .mantine-Indicator-indicator {
        top: 1px;
        right: 5px;
      }
    }

    .eye-button  {
      transition: all 0.2s ease;

      &.animating {
        svg {
          transform: scale(0.9);
          opacity: 0.5;
          transition: all 0.2s ease; 
        }
      }
      
    }

    .light-icon path {
      stroke: #FF8605;
    }

    .theme-button {
      transition: all 0.2s ease;
      min-width: 25px;

      &.animating {
        svg {
          transform: scale(0.9);
          opacity: 0.5;
        }
      }
      
      div {
        display: flex;
      }
    }

    @media (max-width: 976px) {
      gap: 15px;
    }

    @media (max-width: 380px) {
      gap: 10px;
    }
  }
}
