.slider {
  .delimiter {
    background: var(--divider-color);
  }
  
  .security-slide {
    padding: 35px 45px 0;
    border-radius: 8px;
    background-color: var(--table-bg-color);

    @media (width <=976px) {
      background-color: unset;
      padding: 0;
    }

    .security-settings {
      max-width: 740px;

      .profile-hint {
        margin-bottom: 10px;

        @media (width <= 967px) {
          div:first-child {
            align-items: flex-start;
          } 
        }
      }

      svg path {
        fill: none;
      }

      .communication-services {
        display: flex;
        gap: 20px;
        justify-content: space-between;

        .selected-item {
          background: transparent !important;

          .item-content {
            padding-left: 10px;
          }
        }

        .search-container {
          background: var(--table-bg-color);
        }

        @media (width <=976px) {
          flex-direction: column;
          gap: unset;
        }

        .input-text-with-icon {
          border: 1px solid var(--input-border-color);
          border-radius: 8px;
          padding: 10px 15px;
        }

        .input-form-content  li span {
          padding: 10px;

          .selected-item {
            padding: 10px 15px;
            background-color: transparent;

            .sms-type-current {
              color: var(--color-black);
            }
          }
        }

        .input-form {
          padding: 5px 0;
          margin-top: 0;

          .selected-item {
            min-height: 47px;
          }
        }
      }

      .title {
        font: 26px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
        text-transform: none;
        letter-spacing: 1px;

        @media (width <=976px) {
          font-size: 20px;
        }
      }

      .description {
        font: 13px "Inter-Regular", Arial, sans-serif;
        max-width: 650px;
      }

      .additional-protection {
        display: flex;
        flex-direction: column;

        svg path { fill: none;}

        .twofa-settings {
          .twofa-settings-item {
            .method {
              display: flex;
              align-items: center;
              margin-left: 42px;
              margin-top: 7px;

              p {
                font: 12px 'Inter-Regular', Arial, sans-serif;;
                color: var(--color-black);
                margin-right: 10px;
              }

              .btn-link {
                margin-left: 4px;
                white-space: nowrap;
              }
            }
          }

          .toggler-group {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 20px 0;
            width: max-content;

            .radio-group-actions {
              display: flex;
              gap: 20px;
              margin: 10px 0;
            }
          }
        }
      }

      .input-text-with-icon {
        border: 1px solid var(--input-border-color);
        border-radius: 8px;
        padding: 10px 15px;
        min-height: 47px;
      }
    }

    .white-wallets {
      padding-bottom: 20px;
      
      .title {
        font: 26px "Inter-SemiBold", Arial, sans-serif;
      }

      .white-wallets-passport {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        @media (width <= 976px) {
          padding-top: 20px;
          margin-top: 0;
        }
      }   
      
      .description {
        font: 13px "Inter-Regular", Arial, sans-serif;
        max-width: 700px;
        margin-bottom: 10px;
      }

      .wallets-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        p {
          font: 14px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }

        .btn {
          max-width: 206px;
        }
      }
    }

    .delimiter {
      margin: 30px 0;

      @media (width <=976px) {
        margin: 10px 0;
      }
    }

    input {
      outline: none;
      background: none;
      border-radius: 0;
      border: none;
      padding: 0;
    }
  }
}

.mantine-Modal-inner {
  align-items: center !important;
  margin-bottom: 10px;
  overflow: hidden;

  .mantine-Modal-content {
    padding: 10px 0 !important;
  }

  h2 {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
    padding-left: 20px;
  }
}

.mantine-Modal-header {
  button {
    margin-right: 10px !important;
    box-sizing: content-box !important;
  }
}

.form-add-wallet {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
      font: 14px "Inter-Medium", Arial, sans-serif;
      color: var(--color-black);
    }
  }

  .actions-group {
    display: flex;
    gap: 10px;
  }

  .instruments {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .instrument, 
  .network {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;

    .full-name {
      font: 12px "Inter-Light", Arial, sans-serif;
      color: var(--input-comment-color);
    }
  }

  .search-form {
    margin-bottom: 10px;;

    input {
      background: var(--input-bg-color);
      padding: 15px;
    }
  }
}

.actions-margin {
  margin-top: 20px;
}

.form-change-password {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
  
  [class*="formGroup"] {
    max-width: 100%;
  }
}

html[data-theme='light'] {
  .security-settings{
    .search-items {
      background: var(--white);
    }
  }
}

html[data-theme='dark'] {
  .communication-services {
    .input-form {
      @media (width <=976px) {
        .search-items {
          background: var(--color-background) !important;
        }
      }
    }
  }
}