.leverage-settings {
  background: var(--table-bg-color);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px;

  h4 {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
    margin-bottom: 25px;
  }

  p {
    color: var(--color-black);
    font: 16px "Inter-Mediun", Arial, sans-serif;
    margin-bottom: 25px;
    line-height: 1.2;
  }

  .leverage-item-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;

    .leverage-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 47px;
      border-radius: 8px;
      background-color: var(--input-bg-color);
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 1px solid var(--input-border-color);

      &:hover {
        background-color: var(--white);
        border-color: var(--color-main);
      }

      &:active {
        transform: scale(0.98);
      }

      &.selected-item {
        background-color: var(--white);
        border-color: var(--color-main);
      }
    }
  }
}