:root {
  --font-size: 16px;
  --font-family: 'Inter-Regular';
  --height-in-closed-state: 50px;
  --min-width: 320px;
  --max-width: 1920px;
  --max-width-container: 1170px;
  --aside-width: 260px;
  --md1: ($--max-width-container + 12) + px;
  --color-black-15: #151515;
  --color-black-1e: #1E1E1E;
  --color-black-27: #272727;
  --color-black-13: #131313;
  --color-black-1c: ##1C1C1C;
  --white: #FFF;
  --color-red: #FE3944;
  --color-orange: #FF8605;
  --color-main: #00AB6D;
  --color-blue: #00B2FF;

}

html[data-theme='light'] {
  --color-black: #0E0E0E;
  --color-white: #FFF;
  --color-font-gray: #737573;
  --color-background: #F7F6F7;
  --color-background-linear: #F7F6F7;
  --bg-color-for-auth: #0E0E0E;
  --color-border: #3A3A3A;
  --toogle-label-color: #212121;
  --table-column-color: #131313;
  --table-column-text-color: #BCBCBC;
  --table-column-text-color-hover: #00AB6D;
  --table-bg-color: #FFF;
  --table-header-bg-color: #FFF;
  --transfer-bg-color: #FFF;
  --table-border-color: #F5F5F5;
  --checkbox-bg-color: #D9D9D9;
  --input-border-color: #E8E8E8;
  --input-bg-color: #F4F6F4;
  --input-comment-color: #2C2C2C;
  --divider-color: #D9D9D9;
  --qr-code-bg-color: #FFF;
  --widget-block-bg: #EFEFEF;
  --modal-bg-filter-color: #A0A0A0;
  --card-bg-color: #FFF;
  --gray-dark: #3A3A3A;
  --option-btn-bg: #E8E8E8;
  --calendar-bg-color: #FFF;
  --day-empty-divider-bg: var(--color-main);
  --day-empty-divider-shadow: 0 0px 5px var(--color-main);
  --day-empty-bg: #FAFAFA;
  --aside-bg-color: #FFF;
  --table-item-bg-hover: #F7F6F7;
  --multiselect-bg-color: #F7F6F7;
  --trade-table-border-color: #E8E8E8;
  --trade-table-bg-color: #FFF;
  --list-bg-color: #FFF;
  --referrals-bg: #FFF;
  --modal-bg-color: #FFF;
}

html[data-theme='dark'] {
  --color-black: #FFF;
  --color-white: #0E0E0E;
  --color-font-gray: #737573;
  --color-background: #0E0E0E;
  --trade-table-bg-color: #0F1110;
  --color-border: #3A3A3A;
  --toogle-label-color: #FFF;
  --table-column-color: #474747;
  --table-column-text-color: #FFF;
  --table-column-text-color-hover: #00AB6D;
  --table-bg-color: #1E1E1E;
  --table-header-bg-color: #343434;
  --transfer-bg-color: #1D1D1D;
  --checkbox-bg-color: #242424;
  --table-border-color: #272727;
  --input-border-color: #3A3A3A;
  --input-bg-color: #212121;
  --input-comment-color: #FFF;
  --divider-color: #3A3A3A;
  --qr-code-bg-color: #343434;
  --widget-block-bg: #343434;
  --modal-bg-filter-color: #1F1F1F;
  --card-bg-color: #1D1D1D;
  --gray-dark: #737573;
  --option-btn-bg: #222;
  --calendar-bg-color: #151718;
  --calendar-title-color: #434343;
  --day-empty-divider-bg: linear-gradient(41deg, rgb(255 132 1 / 100%) 17%, rgb(208 93 15 / 100%) 65%);
  --day-empty-divider-shadow: 0 -1px 13px var(--color-orange);
  --day-empty-bg: #13131387;
  --color-background-linear: linear-gradient(136deg, #0F1010 0%, #121916 100%);
  --aside-bg-color: linear-gradient(136deg, #0F1010 0%, #121916 100%);
  --table-item-bg-hover: #1E201E;
  --multiselect-bg-color: #151718;
  --trade-table-border-color: #263B30;
  --list-bg-color: #151718;
  --referrals-bg: rgb(21 23 24 / 25%);
  --modal-bg-color: #090909;
}