.bodyFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  z-index: 10;

  @media (width <= 520px) {
    flex-direction: column;
    width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (width <= 976px) {
      gap: 10px;

      li {
        display: flex;
        justify-content: center;
      }
    }

    a {
      color: var(--white);
      font: 12px "Inter-Regular", Arial, sans-serif;
      transition: color 0.3s ease;

      @media (width <= 976px) {
        font-size: 12px;
      }

      &:hover {
        color: var(--color-main);
      }
    }
  }

  .registration {
    color: #3A3A3A;
    font: 12px "Inter-Regular", Arial, sans-serif;
    max-width: 177px;
  }
}