.leverage-page-wrapper {
  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
  }
}

.section-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
  gap: 10px;

  .instrument-list-container {
    width: 59.7%;
    min-width: 650px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 5px;
    max-height: 1600px;
    align-self: flex-start;

    @media (width <= 976px) {
      width: 100%;
    }

    .instrument-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--table-bg-color);
      padding: 20px 35px;
      border-radius: 16px;

      .header-filters-container {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-right: 10px;

        .input-form {
          width: 100%;
          margin: 0;
          min-width: 160px;

          .input-text {
            input {
              padding-right: 35px;
            }
          }
          
          .item-content {
            svg {
              flex: 0 1 auto;
              width: 18px;
              height: 18px;
            }
          }

          .select .search-container {
            background-color: transparent;
          }

          .select-item {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 10px;

            img {
              width: 20px;
              height: 20px;
            }
          }

          svg g path {
            fill: #F3BA2F;
          }
        }
        
        button {
          display: flex;
        }
      }
    }

    .input-text input[type="text"] {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
    }
  }

  .settings-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 39.7%;
    background-color: transparent;
    border-radius: 0;
    padding: 0;

    @media (width <= 976px) {
      width: 100%;
    }

    .search-container {
      .item-content {
        padding: 0 !important;

        span {
          padding: 10px;
        }
      }
    }

    div {
      &:first-child {
        margin-top: 0;
      }
    }

    .delimiter, .input-form, .btn {
      margin-top: 20px;
    }
  }
}

.loader {
  position: static !important;

  .mantine-LoadingOverlay-overlay {
    backdrop-filter: none !important;
    background: none !important;
    inset: auto !important;
  }

}

html[data-theme='dark'] {
  .instrument-list-container {
    .input-form {
      svg path {
        stroke: var(--input-border-color);
      }
    }

    table {
      tr {
        &:nth-child(even) {
          background-color: var(--color-black-13) !important;
          
        }
      }

      td {
        color: var(--white) !important;
      }
    }
  }

  .leverage-item {
    &:hover {
      background-color: var(--color-black-1e) !important;
    }

    &.selected-item {
      background-color: var(--color-black-1e) !important;
    }
  } 
}

html[data-theme='light'] {
  .instrument-list-container {
    table {
      tr {
        &:nth-child(even) {
          background-color: #F9F8F9 !important;
          
        }
      }
    }
  }
}