.container {
  width: 100%;
  
  h2 {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--color-black);
    margin-bottom: 25px;
  }
}

.params {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 25px;
  background-color: var(--table-bg-color);
  border-radius: 16px;

  [class*="input-form"] {
    margin: 0 !important;
  }

  [class*="input-form-title"] {
    p {
      color: var(--color-black) !important;
    }

    margin-bottom: 7px !important;
  }

  .action {
    margin-left: auto;
  }
}

.selectItem {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black);

  &:hover {
    color: var(--white);
  }

  img {
    height: 15px;
  }
}

.selectCurrentItem {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black);

  img {
    height: 15px;
  }
}