.header {
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (width <= 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .divider {
    margin: 30px 0;

    @media (width <= 500px) {
      display: none;
    }
  }

  h1 {
    font: clamp(1.25rem, 1.058rem + 0.96vw, 2.5rem) "Inter-SemiBold", Arial, sans-serif;

    @media (width <= 500px) {
      width: 100%;
      border-bottom: 1px solid var(--trade-table-border-color);
      padding-bottom: 15px;
    }
  }

  .sectionUserIdCopy {
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;

    @media (width <= 500px) {
      padding: 10px 0;
      justify-content: space-between;
      width: 100%;
    }
        
    .userIdTitle {
      font: 13px "Inter-Medium", Arial, sans-serif;
      color: var(--color-black);
  
    }
    
    .userIdWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--trade-table-border-color);
      padding: 5px 10px;
      padding-left: 15px;
      min-width: 100px;
    
      .userIdValue {
        font: 12px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
      }
    }
    
  }
}