.advice-paragraph {
  display: flex;
  align-items: center;
  padding: 3px;

  &.big-black{
    svg {
      width: 40px;
      max-height: 40px;
      align-self: flex-start;
      flex: 0 0 40px;
  
      path {
        stroke: var(--color-black);
      }
    }
  }

  &.warning {
    svg path {
      stroke: var(--color-orange);
    }
  }

  svg {
    max-width: 15px;
    max-height: 15px;
    align-self: flex-start;
    flex: 0 0 40px;

    path {
      stroke: gray;
    }
  }

  p {
    font: 13px 'Inter-Regular', Arial, sans-serif;
    color: #2C2C2C;
    margin-left: 6px;

    @media (width <= 600px) {
      font-size: 12px;
    }

    button {
      border-bottom: 1px solid var(--color-black);
      color: var(--color-black);

      &:hover {
        border-color: var(--color-orange);
        color: var(--color-orange);
      }
    }

    b {
      font-weight: 700;
    }
  }
}

html[data-theme='dark'] {
  .advice-paragraph {
    &.big-black p{
      color: var(--white);
    }
  }
}