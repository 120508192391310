.dustTransformsAssets {
  border-radius: 16px; 
      
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px 30px;
    background: var(--color-white);


    .title {
      color: var(--color-black);
      font: 24px "Inter-Medium";
    }

    .searchInput {
      min-width: 250px;

      input {
        font: 12px "Inter-Regular";
        border-radius: 8px;
        background-color: var(--input-bg-color);
        min-width: 150px;
        color: var(--color-black);
        border-color: var(--input-border-color);
      }

      button {
        display: flex;

        svg path {
          stroke: var(--input-border-color);
        }
      }
    }
  }

  .historyTableWrapper {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
    background-color: var(--table-bg-color);
    padding: 0 10px;
    height: 100%;
    min-height: 345px;
    max-height: 345px;
    overflow: auto;
    border-radius: 0 0 16px 16px;

    .emptyWrapper {
      height: calc(100% - 50px);

      [class*="empty"] {
        height: 100%;
      }
    }
    
    .loadingWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: auto;
    }
    
    table {
      table-layout: fixed;
      width: 100%;
    }
    
    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      text-align: left;
      border-spacing: 0 15px;
      border-collapse: separate;
    }
      
    tr {
      font: 11px "Inter-Regular";
      color: #737573;
      text-align: left;
      height: 42px;

      td:first-child {
        padding-left: 10px;
      }
  
      th:first-child {
        border-top-left-radius: 8px; 
        border-bottom-left-radius: 8px;
        padding: 0 10px;
      }
  
      th:last-child {
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px;
        padding-right: 5px;
      }
    }
      
    th {
      height: 26px;
      cursor: pointer;
      font: 11px "Inter-Regular";
      color: var(--table-column-text-color) !important;
      text-align: left;
      vertical-align: middle;
      
      .checkbox {
        border: none;
        background: #242424;
        transition: border-color 0.3s;

        &:hover {
          border: 1px solid var(--color-main);
        }
      }
      
      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    
    td {
      border-bottom: 1px solid var(--table-border-color);
      font: 13px "Inter-Regular";
      color: var(--color-black);
      vertical-align: middle;
      box-sizing: border-box;
    
 
    }

    .tdWrapper {
      display: flex;
      align-items: center;
      gap: 7px;
      font: 11px "Inter-Regular";
      white-space: nowrap;
      margin-right: 15px;

      &.cursor {
        cursor: pointer;
      }
      
      .symbol {
        font: 11px "Inter-Regular";
      }

      .name {
        font: 9px "Inter-Regular";
        color: gray;
      }

      img {
        height: 15px;
      }
    }

    .thWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
  
      &.activeColumn {
        color: var(--color-main);
  
        svg path {
          stroke: var(--color-main);
        }
      }
    
      &:hover {
        color: var(--table-column-text-color-hover);
  
        svg path {
          stroke: var(--table-column-text-color-hover);
        }
      }
    }
  }
    
  .loadingItemWrapper {
    margin-top: 15px;
  
    .loader {
      height: 10px;
    }
  }

  .convertWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .convertBody {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 10px;
      width: 100%;

      .bodyTitle {
        font: 16px "Inter-Medium";
        color: var(--color-black);
        white-space: nowrap;
      }

      .bodySum {
        font: 24px "Inter-Medium";
        color: var(--color-black);
        white-space: nowrap;
      }
    }

    .convertAction {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 10px;
      width: 100%;

      .selectItem {
        padding: 10px;
      }

      .btnInfo {
        min-width: 60px;
      }
    }
  }
}
  
.checkbox {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  background: var(--checkbox-bg-color);
  border-radius: 4px;
  transition: border-color 0.3s;
  border: 1px solid transparent;
  appearance: none;
  margin-right: 10px;
  margin-left: 5px;

  &:hover {
    border: 1px solid var(--color-main);
  }

  &:focus {
    border: 1px solid var(--color-main);
  }

  &:checked {
    background: var(--color-main);
  }

  &:checked::before {
    content: "✓";
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 12px "Inter-SemiBold";
  }
}

.dragging {
  background: #131313;
}

.notDragging {
  background: initial;
}

.emptyTextError p{
  max-width: 235px !important;
}

html[data-theme='dark'] {
  .dustTransformsAssets {
    .header {
      border: none;
      background-color: #1D1D1D;
      height: unset;

    }

    table {
      background: var(--color-black-1e);

      thead {
        tr {
          th {
            .checkbox {
              background: #D9D9D9;

              &:checked::before {
                color: var(--color-black-1e); 
              }
            }
          }
        }
      }
    }

    .positionCardWrapper {
      .positionCard {
        background: #343434;

        .box {
          .leverage {
            background: #737573;
          }
          
          .averagePrice {
            svg path {
              stroke: white;
            }
          }
        }
      }
    }
  }
}