

.login-verification {
  max-width: 350px;
  margin: 10px 0;

  .login-verification-form-wrapper {
    @media (width <= 976px) {
      max-width: 100%;
    }
  
    .verification-input {
      [class*="hint_error"] {
        margin: 10px 0 0;
      }
    }
  }

  [class*='hintContainer'] {
    margin: 10px 0;
  }

  .item-content span {
    padding: 10px;
    font: 13px "Inter-Regular", Arial, sans-serif;
  }

  .sms-type-current {
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
    background: transparent;
  
    span {
      padding: 10px;
      font: 13px "Inter-Regular", Arial, sans-serif;
    }
  }
    
  .selected-item {
    background: transparent !important;
    font: 14px "Inter-Regular", Arial, sans-serif;
  }

  .search-items {
    background: var(--color-background) !important;
  }

  .enable-select .selected-item{
    border-color: var(--color-main) !important;
  }
}
