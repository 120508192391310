.statistics {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  flex-shrink: 0;

  @media (width <= 1405px) {
    padding-right: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    gap: 10px;
    overflow: unset;
  }

  .widgetContainer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 20px;
    width: 100%;
    min-width: 250px;
    height: auto;
    min-height: 100px;
    background: var(--referrals-bg);
    border: 1px solid var(--trade-table-border-color);
    border-radius: 16px;    

    .exchanges {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 10px 0;

      .skeleton {
        margin: 0 !important;
      }
  
      .exchangeItem {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        background-color: var(--input-bg-color);
        border: 1px solid transparent;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
  
        &.selected {
          border-color: var(--color-main);
        }
  
        &:hover {
          border-color: var(--color-main);
        }
  
        &:focus-visible {
          border-color: var(--color-main);
        }
  
        &:active {
          transform: scale(0.99);
        }
  
        img {
          width: 20px;
          height: 20px;
        }
  
        .exchangeTitle, .value {
          font: 13px "Inter-Regular", Arial,sans-serif;
          color: var(--color-black);
        }
  
        .value {
          margin-left: auto;
        }
      }
    }

    .bonusesWithdrawHint {
      font: 11px "Inter-Light", Arial, sans-serif;
      color: #737573;
      margin-top: 10px;
      width: 100%;
      max-width: 200px;
    }

    .quantity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 10px;
      background: var(--input-bg-color);
      border: 1px solid var(--input-border-color);
      border-radius: 8px;
    
      input {
        color: var(--color-black);
        font: 13px "Inter-Regular", Arial, sans-serif;
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
      }
    
      button {
        font: 13px "Inter-Regular", Arial, sans-serif;
        color: var(--color-main);
        border-bottom: 1px solid var(--color-main);
    
        &:hover {
          border-color: transparent;
        }
  
        &:active {
          transform: scale(0.92);
        }
      }
    }
    
    .title {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
      white-space: pre-wrap;
      margin-bottom: 10px;
      
    }

    .value {
      margin-top: auto;
      font: 1.67vw "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);  

      @media (width <= 1300px) {
        font-size: 30px;
      }
    }

    .btnWithdraw {
      margin-top: 10px;
    }
  }
}