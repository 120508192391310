.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 15px;
  white-space: pre-line;
  padding-top: 10px;


  img {
    width: 115px;
    height: 130px;
  }
  
  h1 {
    font: 26px "Inter-SemiBold", Arial, sans-serif;
    color: var(--white);
  }
  
  p {
    font: 15px "Inter-Regular", Arial, sans-serif;
    color: var(--white);
    line-height: 1.5;
  }

  .supportBtn {
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: #7E7E7E;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      transform: scale(0.99);
    }
  }
}



html[data-theme='light'] {
  .content .supportBtn {
    color: var(--white);
  }
}