.subAccountsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (width <= 976px) {
    flex-direction: row;
  }  
  
  @media (width <= 500px) {
    flex-direction: column;
  }
}