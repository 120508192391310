.components-page {
  background-color: #F7F6F7;

  svg path {
    stroke: black;
  }

  & > div {
    border-top: 1px solid black;
    padding: 15px;
    box-sizing: border-box;

    &:first-child {
      border-top: none;
    }
  }

  .buttons {
    display: flex;
    box-sizing: border-box;

    .btn {
      width: 10%;
      padding: 0 20px;
    }
  }

  .btn-close {
    &::before, &::after {
      left: 26%;
      background-color: black;
    }
  }

  .icons {
    display: flex;
    box-sizing: border-box;
    align-items: center;

    .icon {
      padding: 0 20px;
    }
  }

  .input-form {
    .input-text {
      width: 30%;
    }
  }

  .inputs-text {
    .input-text {
      width: 30%;
      margin-bottom: 10px;
    }
  }

  .selects {
    width: 30%;

    .select {
      margin-bottom: 10px;
    }
  }

  .tooglers {
    .toogler {
      margin-bottom: 10px;
    }
  }
}
