.transferHistory {
  background-color: var(--color-white);
  border-radius: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--table-header-bg-color);
    padding: 20px 35px;

    .button {
      margin-top: 23px;
      margin-left: auto;
    }

    .selectItem {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--color-black);
    }
  }

  .historyTableWrapper {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
    background-color: var(--table-bg-color);
    padding: 0 10px;
    height: 100%;
    min-height: 345px;
    max-height: 345px;
    overflow: auto;
    border-radius: 0 0 16px 16px;

    .loadingWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    table {
      width: 100%;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      text-align: left;
      border-spacing: 0 15px;
      border-collapse: separate;
    }
  
    tr {
      font: 11px "Inter-Regular", Arial, sans-serif;
      color: #737573;
      text-align: left;
      height: 42px;

      th:first-child {
        border-top-left-radius: 8px; 
        border-bottom-left-radius: 8px;
        padding-left: 20px;
      }
  
      th:last-child {
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px;
        padding-right: 5px;
      }
      
      td:first-child {
        padding-left: 20px;
      }
    }
  
    th {
      height: 26px;
      cursor: pointer;
      font: 11px "Inter-Regular", Arial, sans-serif;
      color: var(--table-column-text-color) !important;
      text-align: left;
      vertical-align: middle;
  
      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      svg path {
        stroke: var(--table-column-text-color);
      }
    }

    .historyItem {
      cursor: pointer;
    }

    .thWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
  
      &.activeColumn {
        color: var(--color-main);
  
        svg path {
          stroke: var(--color-main);
        }
      }
    
      &:hover {
        color: var(--table-column-text-color-hover);
  
        svg path {
          stroke: var(--table-column-text-color-hover);
        }
      }
    }

    td {
      border-bottom: 1px solid var(--table-border-color);
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
      vertical-align: middle;
      box-sizing: border-box;

      .createdAt {
        min-width: 100px;
      }

      & > div {
        font: 11px "Inter-Regular", Arial, sans-serif;
      }
    }

    .transferType {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    
    .subAccount {
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        white-space: nowrap;
        margin-right: 5px;
      }

      img {
        height: 15px;
      }
    }
  }

  .loadingItemWrapper {
    margin: 15px 0;

    .loader {
      height: 10px;
    }
  }
}

html[data-theme='dark'] {
  .transferHistory {
    .header {
      border: none;
      background-color: #1D1D1D;
      height: unset;

    }

    table {
      background: var(--color-black-1e);

      thead {
        tr {
          th {
            .checkbox {
              background: #D9D9D9;

              &:checked::before {
                color: var(--color-black-1e); 
              }
            }
          }
        }
      }
    }
  }
}