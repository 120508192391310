.registrationForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;

  & .restoreButton {
    margin-top: -15px;
  }

  @media (width <= 550px) {
    margin-bottom: 0;
    
    [class*="recaptcha_loaded"] {
      div div div {
        height: auto !important;
      }
    }
  }
}