.input-text-wrapper {
  padding: 5px 0;
  width: 100%;
  min-height: 25px;

  .input-text-actions-wrapper {
    display: flex;
    min-width: 63px;
    justify-content: flex-end;
    gap: 5px;

    [class*=' mantine-LoadingOverlay-root'] {
      span {
        width: unset;
        height: unset;
      }
    }
   

    [class*='mantine-LoadingOverlay-loader'] {
      span {
        width: unset;
        height: unset;
      }
      
      &::after {
        width: 1.3rem;
        height: 1.3rem;
        border-width: calc(var(--loader-size) / 11);
      }
    }

    svg {margin-left: auto;}
  }

  time {
    color: var(--color-main);
    font: 12px "Inter-Regular", Arial, sans-serif;
    min-width: 35px;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .input-text-label {
    display: inline-flex;
    font: 14px "Inter-Medium", Arial, sans-serif;
    margin-bottom: 7px;
    color: var(--color-black);
  
  }

  .input-text-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 25px;
    
    &.active-svg {
      svg path {
        fill: var(--color-main) !important;
        stroke: var(--color-white) !important;
      }
    }

    .input-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 25px;

        path {
          stroke: #BDBDBD;
          fill: #BDBDBD;
        }
      }

      p {
        color: var(--white);
        font: 12px 'Inter-Medium', Arial, sans-serif;
        padding: 0 15px;
      }
    }

    .input-text {
      flex: 1;

      input { width: 100%;}
    }

    .btn-link {
      justify-content: flex-end;  
      min-width: 63px;

      button {
        display: flex;
        white-space: nowrap;
      }
    }
  }
  
  .m-b34414df {
    width: 15px;
    height: 15px;

    &::after {
      width: 15px;
      height: 15px;
      border-width: 2px;
    }
  }
}

.loading-center {
  display: flex;
  flex: 0 1 63px;
  justify-content: center;
}


