.footer {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  min-height: 30px;

  @media (width <= 1300px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .statistics {
    display: flex;
    align-items: center;
    min-width: 560px;

    @media (width <= 1300px) {
      min-width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px;
    }

    .divider {
      font: 11px "Inter-Regular", Arial, sans-serif;
      color: #5A5A5A;
      margin: 0 15px;

      @media (width <= 1300px) {
        display: none;
      }
    }

    .item {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: #5A5A5A;

      p {
        color: var(--color-black);
        font: 13px "Inter-Regular", Arial, sans-serif;
      }

      .symbol {
        color: var(--color-black);
        font: 13px "Inter-Regular", Arial, sans-serif;
      }

      .hidden {
        color: var(--color-black);
        font: 13px "Inter-Regular", Arial, sans-serif;
        margin-top: 5px;
      }
    }
  }

  .paginationRoot {
    & > div {
      flex-wrap: nowrap;
    }
    
    .paginationControl {
      background-color: transparent;
      outline: none;
      color: var(--color-black);
      border: none;
    }
      
    .paginationControl[data-active="true"] {
      background-color: var(--white);
      color: #1E201E;
    }
  }
}

