$color-orange: var(--color-orange);
$color-black: var(--color-black-13);
$color-main: var(--color-main);

@mixin set-card-styles($color, $z-index) {
  background-color: $color;
  z-index: $z-index;
}

@mixin card-base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 34px;
  margin-left: -60px;
  border-radius: 8px;
}

.cardContainer {
  display: flex;
  color: var(--white);
  font: 12px 'Inter-SemiBold';

  &.visible {
    display: none;
  }

  & .first,
  & .second,
  & .third {
    @include card-base;
  }

  & .first:nth-child(1) {
    @include set-card-styles($color-orange, 3);
  }

  & .first:nth-child(2) {
    @include set-card-styles($color-black, 2);
  }

  & .first:nth-child(3) {
    @include set-card-styles($color-main, 1);
  }

  & .second:nth-child(1) {
    @include set-card-styles($color-orange, 2);
  }

  & .second:nth-child(2) {
    @include set-card-styles($color-black, 3);
  }

  & .second:nth-child(3) {
    @include set-card-styles($color-main, 1);
  }

  & .third:nth-child(1) {
    @include set-card-styles($color-orange, 1);
  }

  & .third:nth-child(2) {
    @include set-card-styles($color-black, 2);
  }

  & .third:nth-child(3) {
    @include set-card-styles($color-main, 3);
  }
}
