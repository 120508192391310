.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 10px;
  padding: 20px;
  border: 1px solid var(--trade-table-border-color);
  background: var(--referrals-bg);
  border-radius: 16px;

  .inputFormWrapper {
    @media (width <= 1405px) {
      max-width: 100% !important;
    }
  }

  .isSelectValue {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    font: 12px "Inter-Regular", Arial, sans-serif;
    white-space: nowrap;
  
    img, svg {
      display: flex;
      width: 17px;
      max-width: max-content;
      height: 17px;
      flex: 1 0 17px;
          
    }
  
    svg {
      margin-right: 5px;
    }
  
    span {
      width: 100%;
      font: inherit;
      color: #7E7E7E;
    }

    [class*="balance-type_spot"] {
      width: 20px;
      max-width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  [class*="data-picker-container"]{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
   

    @media (width <= 1405px) {
      width: 100%;
    }

    @media (width <= 1405px) {
      [class*="data-picker"] {
        max-width: 100%;
      }
    }
  }

  .empty {
    padding: 5px 10px;
    font: 12px "Inter-Regular", Arial, sans-serif;
    color: #5A5A5A;
  
    &:hover {
      color: var(--white);
    }
  }  
  
  .noData {
    font: 12px "Inter-Regular", Arial, sans-serif;
    color: #5A5A5A;

  }

  .noDataValue {
    font: 12px "Inter-Regular", Arial, sans-serif;
    color: #5A5A5A;
    display: flex;
    width: 100%;
    padding: 10px;

  }

  .searchInput {
    button {
      display: flex;

      svg {
        width: 20px;
        
        path {
          stroke: var(--input-border-color);
        }
      }
    }
  }

  .resetBtn {
    margin-left: auto;

    @media (width <= 1410px) {
      margin-left: 0;
    }
  }
}


html[data-theme='light'] {
  .filters {
    background: var(--white);
  }
}