.transfers-page-wrapper {
  @media (width <= 976px) {
    margin-top: 90px;
    padding: 0 20px;
  }
}

.section-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0;

  @media (width <= 976px) {
    display: flex;
    flex-direction: column;

    .replenishment-wrapper, .withdrawal-wrapper {
      width: 100%;
      min-width: 100% !important;
    }
  }

  & > div {
    width: 49%;
    background-color: var(--table-bg-color);
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;

    &.replenishment {
      .item-content {
        width: 100%;
      }
    }

    &.withdrawal-wrapper {
      min-width: 425px;
      
      .withdrawal-sum,
      .user-white-wallets {
        margin-bottom: 5px;

        .input-form-title .btn-link {
          margin-left: auto;
        }

        .add-wallet {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 30px;
          
          .input-form {
            margin-top: 0;
          }
        }

        .divisors {
          margin-top: 10px;
        }

        .input-text {
          display: flex;
          align-items: center;
          max-height: 42px;
          justify-content: space-between;
          background-color: var(--input-bg-color);
          border-radius: 8px;
          border: 0.5px solid var(--input-border-color);
          box-sizing: border-box;
          outline: none;

          input {
            width: 100%;
            box-sizing: border-box;
            outline: none;
            font: 13px "Inter-Regular", Arial, sans-serif;
            border: none;
            padding: 12px;
          }
        }
      }

      .withdrawal-result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        gap: 20px;

        @media (width <= 600px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }

        .commissions {
          p.sum-to-receive {
            font: 13px 'Inter-Regular', Arial, sans-serif;
            color: var(--color-black);
          }

          p.network-comission {
            font: 12px 'Inter-Regular', Arial, sans-serif;
            color: #737573;
            margin-top: 3px;
          }

          p {
            span {
              margin-left: 4px;
            }
          }
        }

        .btn {
          max-width: 178px;

          @media (width <= 600px) {
            max-width: 100%;
          }
        }
      }
    }

    .input-form {
      margin-top: 25px;
    }

    .instruments {
      display: flex;
      justify-content: space-between;

      @media (width <= 600px) {
        flex-direction: column;
      }

      .input-form {
        width: 49%;

        @media (width <= 600px) {
          width: 100%;
        }

        .select {
          .instrument, .network {
            display: flex;
            align-items: center;
            padding: 10px;

            img {
              width: 20px;
              height: 20px;
            }
    
            span {
              margin-left: 8px;
    
              &.full-name {
                font: 10px 'Inter-Light', Arial, sans-serif;
                color: #737573;
              }
            }
          }
        }
      }  
    }

    .advice-paragraph {
      // margin-top: 20px;
      max-width: 400px;

      @media (width <= 600px) {
        margin-top: 10px;
      }
    }
  }

  .replenishment, .withdrawal-wrapper {
    .title {
      font: 16px "Inter-Regular", Arial, sans-serif;
    }
  }
}

.account-address {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .input-text {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    font: 13px "Inter-Regular", Arial, sans-serif;
    background-color: var(--input-bg-color);
    border-radius: 8px;
    border: 1px solid var(--input-border-color);
    padding: 7px 10px;

    input {
      width: 100%;
      box-sizing: border-box;
      outline: none;
      font: 13px "Inter-Regular", Arial, sans-serif;
      border: none;
      border-radius: 0;
      background: transparent;
      padding: 0;

      @media (width <= 500px) {
        font-size: 11px;
      } 
      
      @media (width <= 400px) {
        font-size: 10px;
      }
    }

    button {
      display: flex;
      
      &:hover {
        svg path {
          stroke: var(--color-main);
        }
      }
    }
  }
}

.input-form-content .key-item{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font: 13px "Inter-Regular", Arial, sans-serif;

  @media (width <=500px) {
    font-size: 11px;
  }

  .key-item-address {
    display: none;
    font: 11px "Inter-Light", Arial, sans-serif;
  }

  &:hover {
    .key-item-address {
      display: flex;
    }
  }
}

.user-accounts {
  .item-content {
    width: 100%;
  }
}

.divisor-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
}

.instrument-observer {
  display: flex;
  align-items: center;
  padding: 10px;

  @media (width <= 600px) {
    flex-wrap: wrap;
    gap: 8px;

    span {
      margin-left: 0 !important;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  span {
    margin-left: 8px;

    &.full-name {
      font: 10px 'Inter-Light', Arial, sans-serif;
      color: #737573;

      @media (width <= 600px) {
        display: none !important;
      }
    }
  }
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  margin-top: 15px;
  border-radius: 8px;
  background-color: #F7F6F7;
  min-height: 325px;

  p {
    text-align: center;
    font: 12px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
    margin-bottom: 20px;
  }

  img {
    margin-top: 12px;
  }
}

.withdrawal-hint {
  p { color: var(--color-red);}
  svg path {stroke: var(--color-red);}
}

.replenishment-wrapper {
  position: relative;
  min-width: 425px;

  .replenishment {
    &.not_active {
      filter: blur(3px);
    }
  }

  .replenishment-passport {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    transform: translate(-50%, -50%);
  }
}

.user-white-wallets-hint {
  display: none;
  align-items: center;
  gap: 5px;
  font: 13px "Inter-Regular", Arial, sans-serif;
  color: var(--color-black);
  margin-top: 5px;
  margin-bottom: -5px;

  svg {
    display: flex;
    width: 20px;

    path {
      stroke: var(--color-orange);
    }
  }

  button {
    border-bottom: 1px solid var(--color-black);
    color: var(--color-black);
    font: inherit;

    &:hover {
      color: var(--color-orange);
      border-color: var(--color-orange);
    }
  }

  &.hint-active {
    display: flex;
  }

}

html[data-theme='dark'] {
  .qr-code {
    background-color: var(--table-bg-color);
  }
}