.apiKeysAdaptive {
  .table {
    display: flex;
    flex-direction: column;
    gap: 10px;
        
    .apiKeyItem{
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: var(--input-bg-color);
      border: 1px solid var(--input-border-color);
      padding: 15px;
      border-radius: 8px;

      .userName {
        font: 13px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
      }

      .exchange {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;

        img {height: 20px;}

        p {
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }
      }

      .active path {
        stroke: var(--color-orange);
      }
    }
  }

  .apiKeysStateViewAdaptive {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
}

.passportWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.skeletonWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  [class*='select-skeleton'] {
    margin-top: 0 !important;
  }
}