.adaptiveBalance {
  .container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .balanceWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      [class*="selectBlock"] {
        span {
          color: var(--color-black);
        }
      } 
      
      [class*="selectListWrapper"] {
        background: var(--input-bg-color);
        border: 1px solid var(--input-border-color);

        span {
          color: var(--color-black);
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .pnl {
        display: flex;
        align-items: center;
        gap: 10px;

        .title {
          font: 12px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }

        .value {
          display: flex;
          align-items: center;
          gap: 5px;
          font: 12px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);

          &.minus {
            color: var(--color-red) !important;
          }

          &.plus {
            color: var(--color-main) !important;
          }
        }
      }

      .action {
        display: flex;
        gap: 5px;
      }
    }
  }
}


html[data-theme="light"] {
  .balanceWrapper {
    [class*="account-block_account"] {
      background: transparent;
      border-color: var(--input-border-color);

      [class*="account-block_value"] {
        span {
          color: var(--color-black);
        }
      }  
      
      [class*="account-block_increase"] {
        span {
          color: var(--color-black);
        }
      }  
      
      [class*="account-block_blockTitle"] {
        color: var(--color-black-15);
      }
    }

    [class*="selectList"] {
      li {
        &:hover {
          span {
            color: white;
          }
        }
      }
    }
  }
}