.input-radio {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      width: 19px;
      height: 19px;
      border-radius: 100%;
      background-color: #E8E8E8;
    }

    p {
      font: 13px 'Inter-Medium', Arial, sans-serif;
      color: var(--color-black);
      margin-left: 8px;
    }
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;

    &:checked {
      + label {
        &::before {
          background-color: #00C37C;
        }

        &::after {
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;
          display: inline-block;
          width: 9px;
          height: 9px;
          border-radius: 100%;
          background-color: var(--white);
        }
      }
    }
  }

  span.comment {
    font: 11px 'Inter-Regular', Arial, sans-serif; 
    color: #737573;
    margin: 1px 0 0 10px;
    text-wrap: nowrap;
  }
}
