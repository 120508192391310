.header-diary {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 75px;
  margin-bottom: 20px;
  background-color: transparent;
  box-sizing: border-box;
  z-index: 1000;

  &_logo {
    width: 150px;
    height: 40px;
    margin-right: 75px;
    flex: 0 0 80px;

  }

  nav {
    display: flex;
    align-items: center;
    margin-right: 20px;
  
    a { 
      position: relative;
      padding-bottom: 4px;
      margin: 6px 40px 0 0;
      font: 14px 'Inter-Regular', Arial, sans-serif;
      color: var(--color-black);
      border-bottom: 1px solid transparent;
      box-sizing: border-box;
      text-decoration: none;
          
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #00C37C;
        transition: width 0.2s ease;
      }
    
      &:hover::before {
        width: 100%;
      }
    
      &:last-child {
        margin-right: 0;
      }
    
    }
  }
  
  .page-customisations {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
  
    .close-positions-btn {
      cursor: pointer;
      transition: all 0.2s ease;
        
      &:active {transform: scale(0.9);}
  
      &:hover {
        path {
          stroke: var(--color-main);
        }
      }
    }
  
    svg {
      display: flex;
      transition: all 0.2s ease; 
    }
  
    @media (width <= 976px) {
      gap: 15px;
    }
  
    @media (width <= 380px) {
      gap: 10px;
    }
  
    .indicator {
      .mantine-Indicator-indicator {
        top: 1px;
        right: 5px;
      }
    }
  
    .theme-button {
      transition: all 0.2s ease;
      min-width: 25px;
  
      &.animating {
        svg {
          transform: scale(0.9);
          opacity: 0.5;
        }
      }
        
      div {
        display: flex;
      }
    }
  
    .eye-button  {
      transition: all 0.2s ease;
  
      &.animating {
        svg {
          transform: scale(0.9);
          opacity: 0.5;
          transition: all 0.2s ease; 
        }
      }
        
    }
  
    .light-icon path {
      stroke: #FF8605;
    }
  
    svg {
      line, path, polyline{
        stroke: var(--color-black);
      }
    
    }
  
    button {
      position: relative;
      display: flex;
      cursor: pointer;
  
      &:last-child {
        margin-right: 0;
      }
  
      &:hover {        
        line, path, polyline{
          stroke: var(--color-main);
        }
      }
    }
  }
}
  
.exit-modal-wrapper {
  max-width: 440px;
  max-height: 250px;

  h2 {
    font: 26px "Manrope-SemiBold", Arial, sans-serif;
    color: var(--color-black);
    padding-left: 20px;
    max-width: 350px;
    margin-bottom: 20px;
  }
}