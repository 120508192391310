.account {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: #131313;
  padding: 10px 15px;
  
  @media (width <= 976px) {
    width: 100%;
  }
  
  .accountBlockTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  
    .blockTitle {
      color: #BDBDBD;
      font: 13px "Inter-Light", Arial, sans-serif;
  
    }
  
    .blockLink {
      a {display: flex;}
  
      svg path { stroke: #3A3A3A;}
  
      &:hover {
        svg path { stroke: var(--color-main);}
      }
  
      &:active {
        transform: scale(0.9);
      }
    }
  }
      
  .value {
    display: flex;
    align-items: center;
    gap: 5px;
    font: 13px "Inter-Regular", Arial, sans-serif;
    margin-bottom: 4px;
  
    .balance {
      display: flex;
      align-items: center;
      gap: 5px;
  
      .valueMoney {
        display: flex;
        color: var(--white);
      }
    }
  
    .availableBalance {
      cursor: pointer;
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: gray;
    }
  
  }
  
  .increase {
    display: flex;
    align-items: center;
    font: 11px "Inter-Regular", Arial, sans-serif;
  
    .resetPnl {
      margin-left: auto;
      cursor: pointer;
  
      &:hover {
        path, polyline {
          stroke: var(--color-main);
        }
      }   
        
      &:active {
        transform: scale(0.9);
      }
    }

    .pnlPercent {
      &.minus {
        color: var(--color-red) !important;
      }

      &.plus {
        color: var(--color-main);
      }
    }
  
    .pnl {
      color: var(--white);
      margin-right: 5px;

      &.minus {
        color: var(--color-red);
      } 
        
      &.plus {
        color: var(--color-main);
      }
    }

    .divider {
      &.minus {
        color: var(--color-red);
      } 
        
      &.plus {
        color: var(--color-main);
      }
    }
  
    .pnlAsset {
      color: var(--white);

      &.minus {
        color: var(--color-red);
      } 
        
      &.plus {
        color: var(--color-main);
      }
    }
  
    .pnlPercent, .divider {
      color: var(--white);
      margin-left: 5px;
    }
  }
  
}