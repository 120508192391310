.transactionHistory {
  border-radius: 16px;
  
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h2 {
      font: 25px "Inter-SemiBold";
      color: var(--color-black);
    }

    .actionsOptions {
      display: flex;
      align-items: center;
      gap: 10px;
        
      button {
        display: flex;
        padding: 5px;
        border-radius: 50%;

        &:hover {
          background: var(--option-btn-bg);

          svg path {
            fill: var(--color-black);
          }

          svg rect {
            fill: var(--color-black);
          }

          svg circle {
            fill: var(--color-black); 
          }
        }
          
      }
    } 
    
    .options {
      position: absolute;
      top: 30px;
      right: 0;
      padding: 15px 25px;
      min-width: 250px;
      background-color: #222;
      border-radius: 8px;
      z-index: 100;
      display: none;
      opacity: 0;
      transition-duration: 0.2s;
      transition-behavior: allow-discrete;

      &.visible {
        display: block;
        opacity: 1;

        @starting-style {
          opacity: 0;
        }
      }

      .optionsTitle {
        color: #E8E8E8;
        font: 13px "Inter-Medium", Arial, sans-serif;
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            span {
              color: var(--color-main);
            }
          }

          input {
            background: #3A3A3A;
            border: none;

              
            &:hover {
              border: 1px solid var(--color-main);
            }

            &:focus {
              border: 1px solid var(--color-main);
            }

            &:checked {
              background: var(--color-main);
            }

            &:checked::before {
              content: "✓";
              position: absolute;
              top: 50%;
              left: 48%;
              transform: translate(-50%, -50%);
              color: var(--white); 
              font: 12px "Inter-SemiBold", Arial, sans-serif;
            }
          }

          span {
            color: #E8E8E8;
            font: 13px "Inter-Medium", Arial, sans-serif;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px 35px;
    background-color: var(--table-bg-color);
    
    .buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 23px;
      margin-left: auto;

      svg {
        cursor: pointer;
      }
    }
  
    .selectItem {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--color-black);
    }
  }
  
  .historyTableWrapper {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
    background-color: var(--table-bg-color);
    padding: 0 10px;
    height: 100%;
    min-height: 345px;
    max-height: 345px;
    overflow: auto;
    border-radius: 0 0 16px 16px;

    .table {
      .tdWrapper {
        &.minus {
          color: var(--color-red);
        }

        &.plus {
          color: var(--color-main);
        }
      }
    }
  
    .loadingWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: auto;
    }
  
    table {
      width: 100%;
    }
  
    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      text-align: left;
      border-spacing: 0 15px;
      border-collapse: separate;
    }
    
    tr {
      font: 11px "Inter-Regular";
      color: #737573;
      text-align: left;
      height: 42px;

      th:first-child {
        border-top-left-radius: 8px; 
        border-bottom-left-radius: 8px;
        padding-left: 20px;
      }
  
      th:last-child {
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px;
      }
      
      td:first-child {
        padding-left: 20px;
      }
    }
    
    th {
      height: 26px;
      cursor: pointer;
      font: 11px "Inter-Regular";
      color: var(--table-column-text-color) !important;
      text-align: left;
      vertical-align: middle;
    
      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  
    .historyItem {
      cursor: pointer;
    }
  
    .thWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;

      &.activeColumn {
        color: var(--color-main);

        svg path {
          stroke: var(--color-main);
        }
      }
    
      &:hover {
        color: var(--table-column-text-color-hover);
  
        svg path {
          stroke: var(--table-column-text-color-hover);
        }
      }
    }
  
    td {
      border-bottom: 1px solid var(--table-border-color);
      font: 13px "Inter-Regular";
      color: var(--color-black);
      vertical-align: middle;
      box-sizing: border-box;
  
      & > div {
        font: 11px "Inter-Regular";
        white-space: nowrap;
        margin-right: 15px;
      }

      .actionTd {
        color: #00C37C;
        background: #00C37C47;
        min-width: 40px;
        width: max-content;
        border-radius: 4px;
        padding: 3px 7px;
        font: 12px "Inter-Regular";
        margin-right: 15px;
      }
       
      .actionTd.sell {
        background: #FF131329;
        color: var(--color-red);
      }

      .pnlTd {
        color: var(--color-main);
        font: 13px "Inter-SemiBold";
      }

      .pnlTd.minus {
        color: var(--color-red);
      }

      .instrumentTd {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      
    }

    .subAccount {
      display: flex;
      align-items: center;
      gap: 5px;
  
      span {
        white-space: nowrap;
        min-width: 55px;
      }
    }
  }
  
  .loadingItemWrapper {
    margin: 15px 0;

    .loader {
      height: 10px;
    }
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  background: #D9D9D9;
  border-radius: 4px;
  transition: border-color 0.3s;
  border: 1px solid #D9D9D9;
  appearance: none;
  margin-right: 10px;
  
  &:hover {
    border: 1px solid var(--color-main);
  }

  &:focus {
    border: 1px solid var(--color-main);
  }

  &:checked {
    background: var(--color-main);
  }

  &:checked::before {
    content: "✓";
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 12px "Inter-SemiBold";
  }
}

html[data-theme='dark'] {
  .transactionHistory {
    .header {
      background-color: #1D1D1D;
    }
  }
}