.message {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 20px;
  background: linear-gradient(180deg, gray -336.01%, transparent 100%);

  .optionsBtn {
    position: absolute;
    top: 17px;
    right: 20px;
    padding: 7px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .optionsBtn svg {
    display: flex;
  }
  
  @media (hover: hover) {
    .optionsBtn:hover {
      background-color: #222;
    }
    
    .optionsBtn:hover svg circle {
      fill: var(--white);
    }
  }
  
  .optionsList {
    position: absolute;
    top: 50px;
    right: 23px;
    border-radius: 9px;
    padding: 15px 10px;
    background: #222;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease;
    min-width: 215px;

    &.open {
      visibility: visible;
      opacity: 1;
    }

    li:first-child {
      font: 13px 'Inter-Regular', Arial, sans-serif;
      margin-bottom: 15px;
      cursor: auto;
      color: var(--white);
      padding: 0 10px;
    }

    li:not(:first-child) {
      border-radius: 8px;
      padding: 7px 10px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #2E2E2E;
        cursor: pointer;
      }
    }

    li{ 
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: var(--white);
      transition: all 0.2s ease;
    }
  }

  &.news {
    background: linear-gradient(180deg, gray -336.01%, transparent 100%);
  }

  &.important {
    background: linear-gradient(180deg, var(--color-main) -340%, transparent 100%);
  }

  &.warning {
    background: linear-gradient(180deg, #FF8605 -336.01%, transparent 100%);
  }

  &.readAny {
    border-left: 3px solid gray;
  }
  
  &.readNews {
    border-left: 3px solid gray;
  }

  &.readImportant {
    border-left: 3px solid var(--color-main);
  }

  &.readWarning {
    border-left: 3px solid #FF8605;
  }

  &:first-child {
    margin-top: 0;
  }

  .messageTitle { 
    display: flex;
    align-items: center;
    gap: 10px;
    height: 20px;
    
    p { 
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: var(--white);
    }

    .type {
      padding: 5px 7px;
      border-radius: 8px;
      background: gray;
      color: var(--white);
      font: 13px 'Inter-Regular', Arial, sans-serif;

      &.important {
        background: var(--color-main);
      }

      &.warning {
        background: #FF8605;
      }

      &.news {
        background: gray;
      }
    }
  }

  .messageBody {
    padding-right: 20px;

    p {
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: var(--white);
      line-height: 1.5;
    }
  }

  .messageTime { 
    font: 11px "Inter-Regular", Arial, sans-serif;
    color: gray;
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  background: #3A3A3A;
  border-radius: 4px;
  transition: border-color 0.3s;
  appearance: none;

  &:checked {
    background: var(--color-main);
  }

  &:checked::before {
    content: "✓";
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 16px "Inter-SemiBold", Arial, sans-serif;;
  }
}

.messageWrapper {
  display: flex;
  gap: 10px;

  .checkboxWrapper {
    display: flex;
    align-items: flex-start;
    margin-left: -30px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease;


    &.visibleCheckbox {
      visibility: visible;
      opacity: 1;
      margin: 0;
    }
  }

  .bodyWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}