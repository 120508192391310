.layout {
  display: flex;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  background: var(--color-background-linear);

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px;
  }
}