.switch-wrapper { 
  margin-left: 10px;

  .verification-status { 
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #2C2C2C;
    white-space: nowrap;

    .status-text {
      font: 13px 'Inter-Regular';
      padding: 10px 15px;
    }

    .verify-kyc-state {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      font: 13px 'Inter-Regular';
      color: var(--white);
      padding: 10px 15px;
      cursor: pointer;
      
    }

    &.created {
      background: #00C37C;
      color: var(--color-white);
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #00A468;
      }
    }

    &.process {
      background: #FF8605;
      color: var(--white);
    }

    &.verified {
      background: #2C2C2C;
      color: var(--white);
      border: 1px solid var(--color-main);
    }

    &.no_verified {
      background: #00C37C;
      color: var(--white);
      transition: background-color 0.2s ease;
      border: 1px solid var(--color-main);

      &:hover {
        background-color: #00A468;
      }
    }
  }

  .switch {
    position: relative;
    width: 18px;
    height: 18px;

    &::before, &::after {
      content: '';
      position: relative;
      bottom: 8px;
      display: inline-block;
      width: 18px;
      height: 2px;
      border-radius: 100px;
      background-color: #2C2C2C;
    }

    &::after {
      transform: rotate(90deg);
      bottom: 33px;
    }
  }
}