.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
    
  .body {
    p {
      font: 10px "Inter-Light", Arial, sans-serif;
      margin-bottom: 10px;
      line-height: 1.5;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}