.loginForm {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
  
  [class*="hint_error"] {
    margin-top: -15px;
    align-items: center;
  }

  @media (width <= 550px) {
    margin-bottom: 0;
    
    [class*="recaptcha_loaded"] {
      div div div {
        height: auto !important;
      }
    }
  }

  a {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  & .restoreButton {
    margin-top: -15px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}



