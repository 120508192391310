.copy-icon {
  button {
    display: flex;
    cursor: pointer;

    &:hover {
      svg  {
        g path {
          stroke: var(--color-main);
        }

        rect {
          stroke: var(--color-main);
        }
      }
    }
  }
}

.copy-icon-disable {
  &:disabled {
    background-color: transparent;
  }
}
