.divisors {
  display: flex;

  .divisors-item {
    display: flex;
    cursor: pointer;
    background-color: #E8E8E8;
    padding: 4px 10px;
    border-radius: 100px;
    margin-left: 3px;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0;
    }

    span {
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: #737573;
    }
  }
}
