.dropdown-menu {
  .dropdown-menu-item {
    position: relative;
    height: 62px;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 5px;
    overflow: hidden;
    transition: height 0.3s ease;

    @media (width <=976px) {
      border-color: var(--color-black);
    }

    .verification-level {
      white-space: nowrap;
    }

    &::before {
      content: '';
      position: absolute;
      top: 10%;
      left: -4px;
      width: 4px;
      height: 80%;
      background-color: #00C37C;
      border-radius: 8px 0 0 8px;
    }

    &_opened {
      transition: height 0.3s ease; 
      height: 100%;
      min-height: 280px;
      max-height: max-content;

      .switch {
        &::before {
          background-color: #00AB6D !important;
        }

        &::after {
          background-color: transparent !important;
        }

      }

      .item-title {
        background: var(--color-black) !important;
      
      }
    }

    .delimiter {
      margin: 0 !important;
      background-color: #E8E8E8 !important;
    }

    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 62px;
      padding: 0 28px;

      @media (width <=976px) {
        background-color: unset;
      }

      .verification-level { 
        font: 14px 'Inter-Medium';
        color: var(--white);
      }

      .switch-wrapper { 
        margin-left: 10px;

        .verification-status { 
          display: flex;
          align-items: center;
          border-radius: 8px;
          background: #2C2C2C;
          white-space: nowrap;

          .status-text {
            font: 13px 'Inter-Regular';
            padding: 10px 15px;
          }

          .verify-kyc-state {
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 150px;
            font: 13px 'Inter-Regular';
            color: var(--white);
            padding: 10px 15px;
            cursor: pointer;
          
          }

          &.created {
            background: #00C37C;
            color: var(--color-white);
            transition: background-color 0.2s ease;

            &:hover {
              background-color: #00A468;
            }
          }

          &.process {
            background: #FF8605;
            color: var(--white);
          }

          &.verified {
            background: #2C2C2C;
            color: var(--white);
            border: 1px solid var(--color-main);
          }

          &.no_verified {
            background: #00C37C;
            color: var(--white);
            transition: background-color 0.2s ease;
            border: 1px solid var(--color-main);

            &:hover {
              background-color: #00A468;
            }
          }
        }

        .switch {
          position: relative;
          width: 18px;
          height: 18px;

          &::before, &::after {
            content: '';
            position: relative;
            bottom: 8px;
            display: inline-block;
            width: 18px;
            height: 2px;
            border-radius: 100px;
            background-color: #2C2C2C;
          }

          &::after {
            transform: rotate(90deg);
            bottom: 33px;
          }
        }
      }
    }

    .item-content { 
      padding: 10px 20px 20px;

      &_verification {
        margin-top: 20px;
        margin-left: -10px;
      }

      .list {
        &_possibilities {
          svg path {
            stroke: #00AB6D;
          }
        }

        .list-item { 
          display: flex;
          margin-top: 10px;

          p {
            font: 12px 'Inter-Regular';
            color: var(--color-black);
            margin-left: 10px;
          }
        }
      }

      span.requirements { 
        font: 13px 'Inter-SemiBold';
        color: var(--color-black);
        margin-top: 15px;
        display: block;
      }
    }
  }
}


html[data-theme='dark'] {
  .dropdown-menu-item {
    &_opened {
      .item-title {
        background-color: #343434 !important;

        @media (width <= 976px) {
          background-color: var(--white) !important;
        }

        .verification-level { 
          font: 14px 'Inter-Medium';
          color: var(--white);
  
          @media (width <= 976px) {
            color: black !important;
          }
        }
      }
    }
  }

}

html[data-theme='light'] {
  .dropdown-menu-item {
    &_opened {
      .item-title {
        .verification-level { 
          @media (width <= 976px) {
            color: white !important;
          }
        }
      }
    }
  }

}