.selected-item {
  input {
    padding-left: 10px;
    width: 100%;
    background: transparent;
    border: none;
    font: 12px "Inter-Regular", Arial, sans-serif;
    margin-right: 5px;
    color: var(--color-black);

    &::placeholder {
      font: 11px "Inter-Regular", Arial, sans-serif;
    }
  }

  svg {
    display: flex;
    flex: 1 0 10px;
    height: 42px;
  }

  .item-content-select {
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: #212121;
  }

  .all-action {
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: gray;

    &:hover, 
    &:focus {
      color: var(--color-black);
    }
  }
}
