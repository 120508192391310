.notifications {
  position: fixed;
  right: -950px; 
  max-width: 400px;
  height: 100vh;
  z-index: 1001;
  background: rgb(17 21 20 / 95%);
  backdrop-filter: blur(20px);
  overflow-y: hidden;
  transition: all 0.3s ease;

  @media (width <= 967px) {
    width: 100%;
    max-width: 100%;
    z-index: 2003;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    
    &.actions-disabled {
      .btn-notify {
        background-color: #1E1E1E !important;
        svg path { stroke: #3A3A3A !important; }
      }

      .read-bg, .green-bg {
        &:active {
          transform: scale(1) !important;
        }
      }
    }

    .btn-notify {
      display: flex;
      align-items: center;
      padding: 9px;
      justify-content: center;
      border-radius: 8px;
      opacity: 0.8;
      min-width: 40px;
      min-height: 40px;


      &.read-bg {
        transition: all 0.2s ease;
        background-color: var(--color-red);
        svg path { stroke: var(--white); }

        &:active {
          transform: scale(0.95);
        }

        &:hover {
          opacity: 1;
        }
      }

      &.green-bg {
        transition: all 0.2s ease;
        background-color: var(--color-main);
        svg path { stroke: var(--white); }

        &:active {
          transform: scale(0.95);
        }

        &:hover {
          opacity: 1;
        }
      }

      svg {
        display: flex;
      }
    }
  }
  
  &.open {
    right: 0;
  }

  .container { 
    width: 400px;
    box-sizing: border-box;
    height: 100%;

    @media (width <= 967px) {
      width: 100%;
      max-width: 100%;
    }

    .container-body {
      padding: 50px 35px 0;

      @media (width <= 967px) {
        padding: 30px 25px 0;
      }
    }

    .messages {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 10px 35px;
      height: calc(100vh - 200px);
      overflow-y: auto;
      margin-bottom: 50px;

      @media (width <= 967px) {
        padding: 10px 25px;
      }
    }

    .delimiter { 
      margin: 15px 0;

      @media (width <= 967px) {
        margin: 20px 0;
      }
    }

    .notifications-header {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .notifications-exit-btn-wrapper {
        margin-left: auto;
        cursor: pointer;
        padding: 2px 5px;

        &:hover .notifications-exit-icon  path {
          stroke: var(--white);
        }

        .notifications-exit-icon { 
          path {
            stroke: gray;
          }
        }
      }

      .notifications-bell-icon path {
        stroke: var(--white);
      }

      h4 { 
        font: 20px 'Inter-Regular', Arial, sans-serif;
        color: var(--white);
        margin-left: 10px;
      }

    
    }

    .message-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      justify-content: center;
      height: calc(100% - 200px);

      p {
        color: var(--white);
        font: 13px "Inter-Regular", Arial, sans-serif;
        text-align: center;
        max-width: 200px;
      }
    }
  }
}

.btn-loader-size {
  .mantine-LoadingOverlay-loader {
    --loader-size: 18px;
  }
}