.divisor {
  display: flex;

  .divisor-item {
    display: flex;
    cursor: pointer;
    background-color: var(--input-border-color);
    padding: 4px 10px;
    border-radius: 100px;
    margin-left: 3px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    color: #737573;

    &.active-divisor {
      background-color: var(--color-main);
      color: var(--color-white);
    }

    &:hover {
      background-color: var(--color-main);
      color: var(--color-white);
    }

    &:first-child {
      margin-left: 0;
    }

    span {
      font: 13px 'Inter-Regular', Arial, sans-serif;
      color: inherit;
    }
  }
}

html[data-theme='dark'] {
  .divisor-item {
    color: var(--white);

    &.active-divisor {
      color: var(--white);
    }

    &:hover {
      color: var(--white);
    }
  }
}