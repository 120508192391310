.adaptiveMenuDrawer {
  [class*="mantine-Drawer-body"] {
    padding: 0;
  }  
  
  [class*="mantine-Drawer-header"] {
    display: none;
  }  
  
  [class*="mantine-Drawer-inner"] {
    top: 60px;
    display: block;
  }

  .adaptiveMenuWrapper {
    background: var(--color-background);
    padding: 0 20px;
    width: 100%;
    overflow: auto;

    .adaptiveBalance {
      margin-top: 20px;
    }
  }
}

.menuItem {
  display: flex;
  align-items: center;
  height: 40px;
  font: 13px "Inter-Regular", Arial, sans-serif;
  color: var(--white);
  text-decoration: none;
  margin-top: 3px;
  padding-left: 10px;
  border-radius: 8px;
  background-color: #1E1E1E;
  cursor: pointer;

  &.active {
    background: linear-gradient(90deg, #1E1E1E 0%, #004E31 100%);
  }

  &:hover {
    background: linear-gradient(90deg, #1E1E1E 0%, #004E31 100%);
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;

  svg { width: 20px;}

  .selectLanguageWrapper {
    position: relative;
    width: 100%;

    .selectLanguage {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      min-height: 42px;
      justify-content: center;
      color: var(--color-white);
      background-color: var(--color-black);
      cursor: pointer;
      font: 13px "Inter-Regular", Arial, sans-serif;
      border-radius: 8px;
      padding: 0 10px;
      transition: all 0.3s ease;
      white-space: nowrap;
      overflow: hidden;
    }
  
    .languageList {
      background-color: var(--color-black);
      border-radius: 8px;
      position: absolute;
      bottom: 45px;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease;

      &.open {
        visibility: visible;
        opacity: 1;
      }
  
      li {
        button {
          width: 100%;
          text-align: left;
          padding: 13px;
          color: var(--color-white);
          cursor: pointer;
          font: 13px "Inter-Regular", Arial, sans-serif;
          border-bottom: 1px solid #3A3A3A;
          border-radius: 10px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.socials {
  margin-top: auto;

  p {
    font: 13px 'Inter-Regular', Arial, sans-serif;
    color: #BDBDBD;
    text-align: center;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 11px 0;

    .socialTelegram {
      &:hover {
        svg path {
          fill: #08C;
        }
      }
    }
    
    .socialDiscord {
      &:hover {
        svg path {
          fill: #5865F2;
        }
      }
    }

    li {
      svg {
        max-width: 100%;

        path {
          fill: #BDBDBD;
        }
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
  
  .registration {
    color: #3A3A3A;
    font: 12px "Inter-Regular", Arial, sans-serif;
    max-width: 177px;
  }

  a {
    color: var(--color-black);
    font: 12px "Inter-Regular", Arial, sans-serif;
    transition: font-size 0.3s ease;
    text-align: center;

    @media (width <= 976px) {
      font-size: 12px;
    }

    &:hover {
      color: var(--color-main);
    }
  }
}

html[data-theme='light'] {
  @media (width <= 976px) {
    .menuItem {
      background-color: var(--white);
      color: var(--color-black-15);
  
      svg path {
        stroke: var(--color-black-1e);
      }
  
      &_active, &:hover {
        background: linear-gradient(90deg, #1E1E1E 0%, #004E31 100%);
        color: var(--white);

        svg path {stroke: var(--color-main);}
      }
    }
  }
}