@font-face {
  font-family: 'Inter-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../../../shared/assets/fonts/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../../shared/assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../../shared/assets/fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('../../../shared/assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../../shared/assets/fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url('../../../shared/assets/fonts/Manrope-ExtraBold.ttf');
}

@font-face {
  font-family: 'Manrope-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../../shared/assets/fonts/Manrope-Bold.ttf');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('../../../shared/assets/fonts/Manrope-SemiBold.ttf');
}

@font-face {
  font-family: 'Manrope-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../../shared/assets/fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../../shared/assets/fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../../../shared/assets/fonts/Manrope-Light.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraLight';
  font-style: normal;
  font-weight: 200;
  src: url('../../../shared/assets/fonts/Manrope-ExtraLight.ttf');
}

@font-face {
  font-family: 'RobotoMono-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../../../shared/assets/fonts/RobotoMono-Light.ttf');
}

@font-face {
  font-family: 'RobotoMono-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../../shared/assets/fonts/RobotoMono-Regular.ttf');
}

@font-face {
  font-family: 'RobotoMono-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../../shared/assets/fonts/RobotoMono-Medium.ttf');
}

@font-face {
  font-family: 'RobotoMono-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('../../../shared/assets/fonts/RobotoMono-SemiBold.ttf');
}

@font-face {
  font-family: 'RobotoMono-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../../shared/assets/fonts/RobotoMono-Bold.ttf');
}